import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import store from './store';
import 'vue-advanced-cropper/dist/style.css';
import Toast, { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Datepicker from 'vuejs3-datepicker';
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import VueHtml2pdf from 'vue3-html2pdf';
import jspdf from 'jspdf';
import '@vuepic/vue-datepicker/dist/main.css';
import "../src/scss/custom.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faEnvelope, faPhone, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhone, faEnvelope, faArrowLeft, faPen, faTrash, faUsers, faBriefcase, faCalendar, faPrint, faPlus);
import router from "./router";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons/faBriefcase";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";

createApp(App)
.component('Datepicker', Datepicker)
.component('font-awesome-icon', FontAwesomeIcon)
.use(Toast)
.use(store)
.use(router)
.use(Vue3SimpleHtml2pdf)
.use(VueHtml2pdf)
// .use(jspdf)
.mount("#app");

