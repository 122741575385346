<template>
    <div class="position-option">
        <div class="tag-container">
            <div v-for="item in selectedItems" :key="item.id" class="tag">
                {{ item.id }}
                <span @click="removeItem(item)" class="close-btn">×</span>
            </div>
        </div>
        <input class="form-control" v-model="searchInput" @input="filterOptions" @keydown.enter.prevent="addItem" @click.stop="showOption = true"
            placeholder="Look Up Info" />
        <ul v-if="filteredOptions.length && showOption">
            <li v-for="option in filteredOptions" :key="option.id" @click="addItem(option)">
                {{ option.name }}
            </li>
        </ul>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue';

export default {
    props: {
        label: String,
        options: Array,
        handleSelcted: Function,
        initialItems: Array | String,
    },
    setup(props) {
        const selectedItems = ref([]);
        const searchInput = ref('');
        const showOption = ref(false)


        const mapIdsToOptions = () => {
            if (!props.initialItems.length) return;
            if (Array.isArray(props.initialItems)) {
                props.initialItems.map(id => {
                    props.options.map(option => {
                        if (option.id === id && !selectedItems.value.some(i => i.id === option.id)) {
                            selectedItems.value.push({ id: option.id, name: option.name });
                        }
                    });
                });
            } else {
                props.options.map(option => {
                    if (option.id === props.initialItems && !selectedItems.value.some(i => i.id === option.id)) {
                        selectedItems.value.push({ id: option.id, name: option.name });
                    }
                });
            }
        };


        const filteredOptions = computed(() => {
            mapIdsToOptions();
            return props.options.filter(option =>
                option.name.toLowerCase().includes(searchInput.value.toLowerCase())
            );
        });

        const addItem = option => {
            if (!selectedItems.value.some(item => item.id === option.id)) {
                selectedItems.value.push({ id: option.id, name: option.name });
                searchInput.value = '';
            }
            props.handleSelcted(selectedItems.value);
        };

        const removeItem = item => {
            selectedItems.value = selectedItems.value.filter(i => i !== item);
            props.handleSelcted(selectedItems.value);
        };

        const filterOptions = () => {
            // You can add additional filtering logic here if needed
        };

        return {
            selectedItems,
            searchInput,
            filteredOptions,
            addItem,
            removeItem,
            filterOptions,
            showOption
        };
    },
};
</script>
  
<style scoped>
.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.tag {
    background-color: #007BFF;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.close-btn {
    cursor: pointer;
    margin-left: 8px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

li {
    padding: 8px;
    cursor: pointer;
}

li:hover {
    background-color: #0476e7;
}

</style>
  