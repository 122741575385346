<template>
  <div class="d-flex justify-content-between pb-2">
    <div class="mb-2">
      <h3>Clubs</h3>
    </div>
    <div class="text-end pe-0">
      <button v-if="canCreate && !loading" @click="showAddClubModal = true" class="btn btn-primary">+ Add Club</button>
    </div>
</div>
<div class="row">
    <div class="col px-0">
      <!-- Table Headers -->
      <div class="card border-0 d-none d-sm-block bg-transparent">
        <div class="card-body py-1">
          <div class="row">
            <div class="col-md-1 col-sm-2"><span class="mini-label">Logo</span></div>
            <div class="col"><span class="mini-label">Name</span></div>
            <div class="col-md-2"><span class="mini-label">State</span></div>
            <div class="col-md-2"><span class="mini-label">All Teams</span></div>
            <div class="col-md-2"><span class="mini-label">My teams</span></div>
            <div class="col-md-2 text-end"><span class="mini-label">Actions</span></div>
          </div>
        </div>
      </div>
    </div>
      <!-- loader -->
      <div v-for="n in 6" v-if="loading && !clubs.length"> 
        <PreLoaderComponent heightPx="95px" />
      </div>
      <!-- Club Listing -->
      <div
        class="card mb-2 card-list-decoration no-deco"
        v-if="clubs.length"
        v-for="(club, index) in clubs"
        :key="index"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-1 col-sm-2 d-flex align-items-center mb-3 mb-sm-0">
              <img
                :src="club.logo || '/images/club-logo-default-thumb.jpg'"
                alt="Club Logo"
                width="60"
                height="60"
              />
            </div>
            <div class="col">
              <h4>{{ club.shortName }}</h4>
              <small class="text-secondary">{{ club.name }}</small>
            </div>
            <div class="col-md-2">
              <small>{{ club.stateName }} ({{ club.stateState }})</small><br />
              <small class="text-secondary"> {{ club.regionName }}</small>
            </div>
            <div class="col-md-2 d-flex align-items-center">
              <small>Total Teams: {{ club.teamsCount }} </small>
            </div>
            <div class="col-md-2 d-flex align-items-center">
              <template v-if="club.myTeamsCount && (club.myTeamsCount > 0)">
                <router-link :to="{ name: 'MyTeams' }">
                  <small>My Teams: {{ club.myTeamsCount }}</small>
                </router-link>
              </template>
              <template v-else>
                <small>My Teams: {{ club.myTeamsCount }}</small>
              </template>
            </div>

            <!-- Actions for clubs added by the logged-in user -->
            <div
              v-if="club.addedBy === getCurrentUser() || (canUpdateALL || canDeleteALL)"
              class="col-md-2 d-flex align-items-center justify-content-end"
            >
              <a v-if="canUpdate" href="#" @click.prevent="openEditModal(club)" class="px-2">Edit</a>
              <span class="text-secondary">|</span>
              <a v-if="canDelete" href="#" @click.prevent="showDeleteConfirmation(club)" class="px-2"
                >Delete</a
              >
            </div>

            <!-- Message for clubs not added by the logged-in user -->
            <div v-else class="col-md-2 d-flex align-items-center justify-content-end">
              <small class="text-muted">No Actions</small>
            </div>
          </div>
        </div>
      </div>
   
  
    <div v-if="!clubs.length && !loading">
      <div class="alert alert-info">
        <p>There are no clubs, add a club to start.</p>
      </div>
    </div>
  
    <!-- Modal for Delete Confirmation -->
    <div v-if="showDeleteModal" class="modal-mask modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Deleting the Club</h5>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              @click="showDeleteModal = false"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-center">Are you sure you want to delete this club?</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-sm btn-secondary-outline"
              @click="showDeleteModal = false"
            >
              No, Cancel
            </button>
            <button class="btn btn-sm btn-danger" @click="deleteClub">Yes, Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for Add and Edit Club -->
    <ModalComponent v-model="showEditClubModal" header="Edit Club">
      <ClubFormComponent
        :initialClub="editingClub"
        @clubSaved="onClubSaved"
        @closeModal="handleCloseModal"
      />
    </ModalComponent>

    <ModalComponent v-model="showAddClubModal" header="Add Club">
      <ClubFormComponent
        @clubSaved="onClubAdded"
        @closeModal="showAddClubModal = false"
      />
    </ModalComponent>
  </div>
</template>
<script>
import { auth } from "../firebase";
import { ref, onMounted } from "vue";
import ModalComponent from "./ModalComponent.vue";
import ClubFormComponent from "./ClubFormComponent.vue";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { useToast } from "vue-toastification";
import PreLoaderComponent from "@/components/PreLoaderComponent.vue";
import ROLES from "../utils/roles.json"
import { getUserRole } from "../utils/auth";
import { onAuthStateChanged } from "firebase/auth";

export default {
  components: {
    ModalComponent,
    ClubFormComponent,
    PreLoaderComponent,
  },
  setup() {
    const clubs = ref([]);
    const showAddClubModal = ref(false);
    const showEditClubModal = ref(false);
    const editingClub = ref(null);
    const showDeleteModal = ref(false);
    const selectedClub = ref(null);
    const loading = ref(true)

    const userRole = ref("")
    const canCreate = ref(false)
    const canUpdateALL = ref(false)
    const canDeleteALL = ref(false)
    const canUpdate = ref(false)
    const canDelete = ref(false)

    onAuthStateChanged(auth, async (user) => {
      userRole.value = await getUserRole(user);
      canCreate.value = ROLES.CLUB.CREATE.includes(userRole.value?.toLowerCase())
      canUpdate.value = ROLES.CLUB.EDIT.includes(userRole.value?.toLowerCase())
      canDelete.value = ROLES.CLUB.DELETE.includes(userRole.value?.toLowerCase())
      canUpdateALL.value = ROLES.CLUB.EDITALL.includes(userRole.value?.toLowerCase())
      canDeleteALL.value = ROLES.CLUB.DELETEALL.includes(userRole.value?.toLowerCase())
    });

    const fetchUserTeamsCountForClub = async (club) => {
      const teamsCollection = collection(db, "teams");
      const querySnapshot = await getDocs(
        query(teamsCollection, where("club", "==", club))
      );
      return querySnapshot.size;
    };

    const getCurrentUser = () => {
      return auth.currentUser ? auth.currentUser.uid : null;
    };

    const fetchStateForClub = async (stateId) => {
      if (!stateId) return { name: "N/A", state: "N/A" };

      try {
        const stateDoc = await getDoc(doc(db, "states", stateId));
        if (stateDoc.exists()) {
          const stateData = stateDoc.data();
          return {
            name: stateData.name || "N/A",
            state: stateData.state || "N/A",
          };
        }
      } catch (error) {
        console.error("Error fetching state:", error);
      }

      return { name: "N/A", state: "N/A" };
    };

    const fetchRegionForClub = async (regionId) => {
      if (!regionId) return "N/A"; // Return "N/A" if regionId is not available

      try {
        const regionDoc = await getDoc(doc(db, "regions", regionId));
        if (regionDoc.exists()) {
          return regionDoc.data().name || "N/A";
        }
      } catch (error) {
        console.error("Error fetching region:", error);
      }

      return "N/A"; // Return "N/A" as default if region name is not fetched
    };

    const fetchClubs = async () => {
      try {
        const clubsCollection = collection(db, "clubs");
        const clubsSnapshot = await getDocs(clubsCollection);

        const clubFetches = clubsSnapshot.docs.map(async (clubDoc) => {
          const clubData = clubDoc.data();
          clubData.teamsCount = await fetchUserTeamsCountForClub(clubDoc.id);
          const fetchedState = await fetchStateForClub(clubData.stateId);
          clubData.myTeamsCount = await fetchUserCreatedTeamsCountForClub(clubDoc.id); // Add this line
          clubData.stateName = fetchedState.name;
          clubData.stateState = fetchedState.state;
          clubData.regionName = await fetchRegionForClub(clubData.regionId); // Fetch region name
          return { id: clubDoc.id, ...clubData };
        });

        clubs.value = await Promise.all(clubFetches);
        loading.value = false
      } catch (error) {
        console.error("Error fetching clubs:", error);
      }
    };

    const fetchUserCreatedTeamsCountForClub = async (club) => {
      const teamsCollection = collection(db, "teams");
      const querySnapshot = await getDocs(
        query(
          teamsCollection,
          where("club", "==", club),
          where("ownerId", "==", getCurrentUser()) // Use "ownerId" here
        )
      );
      return querySnapshot.size;
    };

    onMounted(fetchClubs);

    const openEditModal = (club) => {
      editingClub.value = { ...club }; // Cloning the club data
      showEditClubModal.value = true;
    };

    const onClubSaved = () => {
      showEditClubModal.value = false;
      fetchClubs(); // Fetch clubs again after saving
    };

    const handleCloseModal = () => {
      showEditClubModal.value = false;
      editingClub.value = null; // Reset editing club
    };

    const onClubAdded = () => {
      showAddClubModal.value = false;
      fetchClubs(); // Fetch clubs again after adding
    };

    const showDeleteConfirmation = (club) => {
      selectedClub.value = club;
      showDeleteModal.value = true;
    };

    const deleteClub = async () => {
      if (selectedClub.value) {
        try {
          const clubRef = doc(db, "clubs", selectedClub.value.id);
          await deleteDoc(clubRef); // Delete club from Firestore

          const index = clubs.value.findIndex(
            (club) => club.id === selectedClub.value.id
          );
          if (index !== -1) {
            clubs.value.splice(index, 1); // Remove from local array
          }

          showDeleteModal.value = false; // Close the modal
          selectedClub.value = null; // Reset selected club

          const toast = useToast();
          toast.success("Club deleted successfully!");
        } catch (error) {
          console.error("Error deleting club:", error);
          const toast = useToast();
          toast.error("Error deleting club!");
        }
      }
    };

    return {
      getCurrentUser,
      clubs,
      showAddClubModal,
      showEditClubModal,
      editingClub,
      openEditModal,
      onClubSaved,
      handleCloseModal,
      onClubAdded,
      showDeleteModal,
      selectedClub,
      showDeleteConfirmation,
      deleteClub,
      canCreate,
      canUpdate, 
      canUpdateALL, 
      canDelete ,
      canDeleteALL,
      loading
    };
  },
};
</script>
<style lang="scss" scoped>
$gray-60: #777;
.no-deco {
  a {
    text-decoration: none;
  }
}
.card-list-decoration {
  transition: all ease-in-out 0.5s;
  &:hover {
    background-color: #f6fbff;
    box-shadow: 1px 1px 18px #b7c7cd;
  }
}
.mini-label {
  color: $gray-60;
  font-size: 0.8rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
