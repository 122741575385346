<template>
    <div class="skeleton mb-2" :style="{ height: heightPx }">
	  	<div class="skeleton-left flex1">
	      <div class="square"></div>
	    </div>
  	    <div class="skeleton-right flex2">
			<div class="line h17 w40 m10"></div>
			<div class="line"></div>
			<div class="line h8 w50"></div>
			<div class="line  w75"></div>
	    </div>
  </div>
</template>

<script>
export default {
    props: {
        heightPx: {
            type: String,
            required: true, // You can make the prop required if necessary
        },
    },
};
</script>

<style scoped>
@import "../../public/style/preLoader.css";
</style>
