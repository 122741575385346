<template>
  <div class="container pt-0 pt-sm-4">
    <div class="row">
      <div class="col-6 col-xs-6">
        <small class="ps-0 ms-0 mt-0 pt-0 fs-6 fw-1">
          <router-link
            to="/my-teams"
            class="btn btn-link me-2 ms-0 ps-0"
          >
            <font-awesome-icon
              :icon="['fas', 'arrow-left']"
              class="me-1"
            />Back<span class="d-none d-sm-inline-block"> to teams</span>
          </router-link>
        </small>
      </div>
      <div class="col-6 col-xs-6 text-end">
        <small class="ps-0 ms-0 mt-0 pt-0 fs-6 fw-1">
          <router-link
            v-if="isAddTeamOwnerLinkShow"
            :to="{ name: 'AddTeamOwner', params: { teamId: team.id }, query: { gender: team.gender, leagueName: league.shortName, clubName: team.clubDetails.shortName, age: team.ageGroup, leagueLogo: league.logo } }"
            class="btn btn-link"
          >
            <font-awesome-icon
              :icon="['fas', 'plus']"
              class="me-1"
            />
            Add Team Owner
          </router-link>
        </small>
      </div>
    </div>
    <div class="row pt-4 pt-sm-2 pb-2">
      <div class="col-10 col-sm-9">
        <div class="name-side d-flex">
          <img
            v-if="team.clubDetails"
            :src="team.clubDetails?.logo || '/images/club-logo-default-thumb.jpg'"
            alt="Club Logo"
            class="me-2 pe-0 text-end"
            style="width: 50px; height: 50px"
          />
          <h3 class="pt-1 pb-0 mb-0">{{ team.clubDetails?.shortName }} - {{ team.ageGroup }} - {{ team.gender }}</h3>
        </div>
      </div>
      <div
        v-if="league && league.shortName !== 'No League'"
        class="col-2 col-sm-3"
      >
        <div class="league-side d-flex flex-column align-items-end">
          <img
            v-if="league && league.logo"
            :src="league.logo"
            alt="League Logo"
            class="me-0 mb-1"
            style="width: 50px; height: 50px"
          />

          <small class="d-none d-sm-block text-muted">{{ league && league.shortName }}</small>
        </div>
      </div>
    </div>
    <div class="row d-none d-sm-block">
      <div class="col">
        <span class="text-muted"
          ><small>{{ team.shortName }}</small></span
        >
      </div>
    </div>
    <ul class="nav nav-tabs mt-4">
      <li class="nav-item">
        <strong
          ><a
            :class="{ active: activeTab === 'squad' }"
            class="nav-link py-4 px-4 px-sm-5 h3"
            @click.prevent="changeTab('squad')"
          >
            <font-awesome-icon
              :icon="['fas', 'users']"
              class="icon-color"
            />
            <span class="d-none d-sm-inline-block ms-2">Team Squad</span></a
          ></strong
        >
      </li>
      <li class="nav-item">
        <strong
          ><a
            :class="{ active: activeTab === 'staff' }"
            class="nav-link py-4 px-4 px-sm-5 h3"
            @click.prevent="changeTab('staff')"
          >
            <font-awesome-icon
              :icon="['fas', 'briefcase']"
              class="icon-color"
            />
            <span class="d-none d-sm-inline-block ms-2">Staff</span></a
          ></strong
        >
      </li>
      <li class="nav-item">
        <strong
          ><a
            :class="{ active: activeTab === 'teamEvents' }"
            class="nav-link py-4 px-4 px-sm-5 h3"
            @click.prevent="changeTab('teamEvents')"
            ><font-awesome-icon
              :icon="['fas', 'calendar']"
              class="icon-color"
            /><span class="d-none d-sm-inline-block ms-2">Events</span></a
          ></strong
        >
      </li>
      <li
        class="nav-item"
        v-if="canViewPrintTab || canViewPrintTabAsMember"
      >
        <strong
          ><a
            :class="{ active: activeTab === 'teamSheet' }"
            class="nav-link py-4 px-4 px-sm-5 h3"
            @click.prevent="changeTab('teamSheet')"
            ><font-awesome-icon
              :icon="['fas', 'print']"
              class="icon-color"
            /><span class="d-none d-sm-inline-block ms-2">Print Sheet</span></a
          ></strong
        >
      </li>
    </ul>

    <!-- PLAYERS TAB -->
    <div
      id="squad"
      v-show="activeTab === 'squad'"
      class="bg-white border p-4"
    >
      <ListSquad
        v-if="activeTab === 'squad'"
        :gender="team.gender"
        :leagueName="team.shortName"
        :clubName="team.clubDetails?.shortName"
        :age="team.ageGroup"
        @updateView="setCanViewPrintTabAsMember"
      />
    </div>
    <!--STAFF TAB -->
    <div
      id="staff"
      v-show="activeTab === 'staff'"
      class="bg-white border p-4"
    >
      <ListStaff
        v-if="activeTab === 'staff'"
        @updateView="setCanViewPrintTabAsMember"
      />
    </div>

    <!--EVENTS TAB -->
    <div
      id="teamEvents"
      v-show="activeTab === 'teamEvents'"
      class="bg-white border p-4"
    >
      <ListEvents v-if="activeTab === 'teamEvents'" />
    </div>

    <!--PRINT TAB -->
    <div
      v-if="canViewPrintTab || canViewPrintTabAsMember"
      id="teamSheet"
      v-show="activeTab === 'teamSheet'"
      class="bg-white border p-4"
    >
      <listAll
        v-if="activeTab === 'teamSheet'"
        :league="league"
      />
    </div>
  </div>
</template>

<script>
import ListSquad from "./ListSquad.vue";
import ListStaff from "./ListStaff.vue";
import ListEvents from "./ListEvents.vue";
import ListAll from "./ListAll.vue";
import { ref, onMounted, computed } from "vue";
import { db, auth } from "../firebase";
import { useRoute } from "vue-router";
import { collection, doc, getDoc, getDocs, query, where, addDoc, updateDoc } from "firebase/firestore";
import { getUserRole } from "../utils/auth";
import ROLES from "../utils/roles.json";

export default {
  components: {
    ListSquad,
    ListStaff,
    ListEvents,
    ListAll,
  },
  setup() {
    const isLoading = ref(true);
    const canViewPrintTab = ref(false);
    const canViewPrintTabAsMember = ref(false);
    const activeTab = ref("squad");
    const team = ref({
      clubDetails: {
        logo: "",
        shortName: "",
      },
    });

    const club = ref({});
    const league = ref({});
    const route = useRoute();
    const userRole = ref("");
    const formatYear = (input) => {
      if (input && typeof input.toDate === "function") {
        return input.toDate().getFullYear();
      } else if (input && typeof input === "string") {
        return input.split("-")[0];
      }
      return "";
    };

    const changeTab = (tabName) => {
      activeTab.value = tabName;
      //window.location.hash = tabName; // Update the URL hash without a page reload
    };

    // watchEffect(() => {
    //   // Check the URL's hash to determine the active tab on page load/refresh
    //   const hash = window.location.hash.replace("#", "");
    //   if (["squad", "staff", "teamEvents", "teamSheet"].includes(hash)) {
    //     activeTab.value = hash;
    //   }
    // });

    const fetchData = async () => {
      try {
        const teamId = route.params?.teamId ?? "";

        // Fetch team
        const teamDoc = await getDoc(doc(db, "teams", teamId));
        if (teamDoc.exists()) {
          team.value = teamDoc.data();
        } else {
          console.error("No such team exists!");
        }

        // Fetch the club's details using the club's ID from the team
        if (team.value && team.value.club) {
          const clubDoc = await getDoc(doc(db, "clubs", team.value.club));
          if (clubDoc.exists()) {
            team.value.clubDetails = clubDoc.data();
          } else {
            console.error("No such club exists!");
          }
        }
        if (team.value && team.value.league) {
          const leagueDoc = await getDoc(doc(db, "leagues", team.value.league));
          if (leagueDoc.exists()) {
            league.value = leagueDoc.data();
          } else {
            console.error("No such league exists!");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchUser = async () => {
      try {
        userRole.value = await getUserRole(auth.currentUser);
        canViewPrintTab.value = ROLES.EVENT.PRINT_TAB_VIEW.includes(userRole.value?.toLowerCase());
        const isUserOwnerOfTeam = Array.isArray(team.value.ownerId) ? team.value.ownerId.includes(auth.currentUser.uid) : team.value.ownerId == auth.currentUser.uid;
        if (userRole.value === "teamowner" && !isUserOwnerOfTeam) {
          canViewPrintTab.value = false;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Function to add a team to the visited teams list
    const addToVisitedTeams = async () => {
      const teamId = route.params?.teamId ?? "";
      const stringQuery = query(collection(db, "recruiterSearchHistory"), where("userId", "==", auth.currentUser.uid));
      const stringQuerySnapshot = await getDocs(stringQuery);
      if (stringQuerySnapshot.size) {
        const data = stringQuerySnapshot.docs[0].data();
        const SearchArray = data.search;
        const updatedArray = addingSearchResultIntoArray(SearchArray, teamId);
        const currentRecruiterDoc = doc(db, "recruiterSearchHistory", stringQuerySnapshot.docs[0].id);
        await updateDoc(currentRecruiterDoc, {
          search: [...updatedArray],
          userId: auth.currentUser.uid,
        });
      } else if (recruiterSearchTeams.value.length == 0) {
        const docRef = await addDoc(collection(db, "recruiterSearchHistory"), {
          search: [teamId],
          userId: auth.currentUser.uid,
        });
      }
    };
    const addingSearchResultIntoArray = (existingArray, teamID) => {
      const index = existingArray.indexOf(teamID);
      if (index !== -1) {
        // If the team is already in the list, remove it from its current position
        existingArray.splice(index, 1);
      }
      // Add the team to the beginning of the list
      existingArray.unshift(teamID);

      // Keep only the 10 most recent visited teams
      if (existingArray.length > 10) {
        existingArray.pop();
      }

      return existingArray;
    };
    const setCanViewPrintTabAsMember = (isSame) => {
      if (isSame) canViewPrintTabAsMember.value = true;
    };
    const isAddTeamOwnerLinkShow = computed(() => {
      if (userRole.value === "teamowner") {
        return Array.isArray(team.value?.ownerId) ? team.value.ownerId.includes(auth.currentUser.uid) : team.value.ownerId == auth.currentUser.uid;
      } else if (userRole.value === "superadmin") return true;
      return false;
    });
    onMounted(async () => {
      activeTab.value = route.query.acitveTab ? route.query.acitveTab : "squad";
      await fetchData(); // Call fetchData when the component mounts
      await fetchUser(); // Call fetchUser when the component mounts
      userRole.value == "recruiter" && (await addToVisitedTeams());
      isLoading.value = false; // Set isLoading to false after fetching data
    });

    return {
      activeTab,
      league,
      club,
      team,
      isAddTeamOwnerLinkShow,
      changeTab,
      isLoading,
      formatYear,
      canViewPrintTab,
      canViewPrintTabAsMember,
      setCanViewPrintTabAsMember,
    };
  },
};
</script>
<!-- STYLES -->

<style scoped lang="scss">
$xs-screen: 575px;

a {
  text-decoration: none;
  cursor: pointer;
}

.item-card {
  &:hover {
    box-shadow: 0 0 12px #999;

    img {
      background-size: 115%;
      background-repeat: no-repeat;
    }
  }

  .svg-inline--fa {
    color: #93bac9;
  }

  .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all ease-in-out 4ms;
    cursor: pointer;
  }
}

.loading-spinner {
  /* Your loading spinner styles */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $xs-screen) {
  .pos-top {
    position: absolute;
    top: 117px;
  }
}
</style>
