<template>
  <div class="container py-4 home bg-transparent">
    <TeamHome />
  </div>
</template>

<script>
// @ is an alias to /src
import TeamHome from "@/components/TeamHome.vue";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ref, onMounted, onUnmounted } from "vue";
import { auth } from "../firebase";
import { signOut, onAuthStateChanged } from "firebase/auth"; // import the signOut function and onAuthStateChanged from the auth library
import { useToast } from "vue-toastification";

export default {
  name: "Home",
  components: {
    TeamHome,
  },

  setup() {
    const name = ref("");
    let unsubscribe; // Create a variable for the unsubscribe function

    onMounted(() => {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userRef = doc(db, "profiles", user.uid); // Referencing the user's profile using their uid
            const userProfile = await getDoc(userRef);

            if (userProfile.exists()) {
              const userData = userProfile.data();
              name.value = userData.firstName || user.email; // If firstName doesn't exist, use email
            } else {
              name.value = user.email; // Default to email if there's no user profile
            }
          } catch (error) {
            console.error("Error fetching user profile:", error);
          }
        }
      });
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    const logout = async () => {
      try {
        await signOut(auth);
        const toast = useToast();
        toast.success("You have successfully logged out!");
      } catch (error) {
        console.error("Failed to sign out:", error);
      }
    };

    return {
      name,
      logout,
    };
  },
};
</script>
