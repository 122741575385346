<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/"
        ><img src="../assets/images/Team-Sheet-Pro-horiz-rev.png"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="d-flex flex-column w-100">
        <div class="d-none d-sm-flex justify-content-end">
          <div class="d-flex justify-content-end align-items-center">
            <div
              class="d-none d-sm-flex profile-avatar me-2"
              :style="{ 'background-image': `url(${profile.picture})` }"
            ></div>
            <small class="text-light me-2"
              >User: {{ displayName }} ({{ store.getters.getCurrentUserProfile }})</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <router-link to="/" class="nav-link" active-class="active"
                    >Home</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/about-us" class="nav-link" active-class="active"
                    >About Us</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/leagues" class="nav-link" active-class="active"
                    >Leagues</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/my-clubs" class="nav-link" active-class="active"
                    >Clubs</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/my-teams" class="nav-link" active-class="active"
                    >Teams</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/my-events" class="nav-link" active-class="active"
                    >Events</router-link
                  >
                </li>
                <li>
                  <router-link to="/contact" class="nav-link" active-class="active"
                    >Contact Us</router-link
                  >
                </li>

                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Account
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <router-link
                        to="/update-Profile"
                        class="dropdown-item"
                        active-class="active"
                        >Edit Profile</router-link
                      >
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" @click="logout">Logout</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted } from "vue";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const profile = ref({ picture: "" });
    const displayName = ref("");
    const router = useRouter();
    const store = useStore(); // Access the Vuex store

    auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser && currentUser.emailVerified) {
        try {
          const profileSnap = await getDoc(doc(db, "profiles", currentUser.uid));
          if (profileSnap.exists() && profileSnap.data().firstName) {
            displayName.value = profileSnap.data().firstName;
            profile.value = profileSnap.data(); // Set the profile picture
            store.dispatch("updateCurrentUser", profile.value);
          } else {
            displayName.value = currentUser.email;
            profile.value.role = profileSnap.data().role;
            store.dispatch("updateCurrentUser", profile.value);
          }
        } catch (error) {
          console.log("Error fetching profile:", error);
          displayName.value = currentUser.email; // Fallback to email if there's an error
        }
      }
    });

    const logout = () => {
      auth
        .signOut()
        .then(() => {
          router.push({ name: "Login" });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      logout,
      profile,
      displayName,
      store,
    };
  },
};
</script>

<style scoped lang="scss">

$xs-screen: 575px;

.navbar > .container-fluid {
  
}

.navbar-brand {
  flex-grow: 1;
  max-width: 72%;

  img {
    max-width: 15em;
    @media screen and (max-width: $xs-screen) {
      max-width: 96%;
    }
  }
}

.profile-avatar {
  width: 2rem;
  height: 2rem;
  background-size: cover;
  background-position: center center;
  border: 2px solid #eee;

  border-radius: 50%;
}
</style>
