<template>
  <div class="card px-0 container">
    <div class="card-body bg-accent-800 rounded">
      <form
        @submit.prevent="saveMember"
        class="row strong-label"
      >
        <div class="col-md-4">
          <div class="card">
            <div class="card-body text-center">
              <!-- Static Image -->

              <img
                v-if="!croppedImageBlob && !member.photo && !cropperModalOpen"
                src="/images/team-member-profile.jpg"
                class="img-fluid"
              />

              <!-- Saved/Cropped Image -->
              <img
                v-if="member.photo && !croppedImageBlob"
                :src="member.photo"
                class="img-fluid"
              />

              <!-- Newly Cropped Image -->
              <img
                v-if="croppedImageBlob"
                :src="createObjectURL(croppedImageBlob)"
                class="img-fluid"
              />

              <!-- Loading spinner -->
              <div
                v-if="isProcessing"
                class="loading-spinner"
              ></div>
              <!-- Cropper Component -->
              <CropperComponent
                :modalOpen="cropperModalOpen"
                @cropperOpened="cropperModalOpen = true"
                @blobReady="handleCroppedImage"
                @imageBeingCropped="setProcessingState(true)"
                @imageCropped="setProcessingState(false)"
                @cropperClosed="cropperModalOpen = false"
                :stencil-size="{ width: 300, height: 300 }"
                :stencil-props="{
                  handlers: {},
                  movable: false,
                  resizable: false,
                  aspectRatio: 1,
                }"
                image-restriction="stencil"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card bg-transparent text-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>Team <span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="member.teamId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a Team
                    </option>
                    <option
                      v-for="team in teams"
                      :key="team.id"
                      :value="team.id"
                    >
                      {{ team.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-4">
                  <label>First Name <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="member.firstName"
                    required
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-12 mb-4">
                  <label>Middle Name</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="member.middleName"
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-12 mb-4">
                  <label>Last Name <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="member.lastName"
                    required
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-6 mb-4">
                  <label>Title<span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="selectedTitleId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a Title
                    </option>
                    <option
                      v-for="title in titles"
                      :key="title.id"
                      :value="title.id"
                    >
                      {{ title.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-4 mb-4">
                  <label>Phone</label>
                  <input
                    class="form-control"
                    type="tel"
                    v-model="member.phone"
                  />
                </div>
                <div class="col-md-5 mb-4">
                  <label>Email</label>
                  <input
                    class="form-control"
                    type="email"
                    v-model="member.email"
                  />
                  <span
                    v-if="emailMsg"
                    class="text-danger"
                    >{{ emailMsg }}</span
                  >
                </div>

                <div class="col-md-12 mb-4">
                  <label>Member Bio</label>
                  <textarea
                    class="form-control"
                    v-model="member.bio"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 mb-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="isProcessing"
                >
                  Save
                </button>
                <button
                  class="btn btn-outline-light"
                  type="button"
                  @click="closeModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { auth, db, storage, authAdmin } from "../firebase";
import CropperComponent from "./CropperComponent.vue";
import { collection, addDoc, getDocs, updateDoc, doc, query, where, setDoc } from "firebase/firestore";
import { ref as fbRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useToast } from "vue-toastification";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ROLES from "../utils/roles.json";
import { getUserRole } from "../utils/auth";

export default {
  props: {
    initialMember: {
      type: Object,
      default: () => ({}),
    },
    teamId: {
      type: String,
      default: null,
    },
  },

  components: {
    CropperComponent,
  },

  setup(props, { emit }) {
    const isProcessing = ref(false);
    const toast = useToast();
    const cropperModalOpen = ref(false);
    const croppedImageBlob = ref(null);
    const titles = ref([{ id: "", name: "" }]);
    const nameError = ref(false);
    const emailMsg = ref(false);
    const nameRegex = /^[a-zA-Z\s\-']*$/; // added hyphens and apostrophes
    const userRole = ref("");
    const canViewAll = ref(false);
    const member = ref(
      props.initialMember || {
        teamId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        titleId: "",
        phone: "",
        email: "",
        bio: "",
        photo: "",
      }
    );
    const staffMembers = ref([]);
    const teams = ref([]);

    const selectedTitleId = computed({
      get: () => {
        const title = titles.value.find((t) => t.id === member.value.titleId);
        return title ? title.id : "";
      },
      set: (value) => {
        member.value.titleId = value;
      },
    });

    watch(
      () => props.teamId,
      (newTeamId) => {
        member.value.teamId = newTeamId;
      }
    );

    watch(
      () => props.initialMember,
      (newMember, oldMember) => {
        if (newMember && (!oldMember || newMember.id !== oldMember.id)) {
          member.value = { ...newMember };
        }
      },
      { immediate: true }
    );

    const fetchTitles = async () => {
      const titleCollection = collection(db, "titles");
      const titleSnapshot = await getDocs(titleCollection);
      return titleSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    const generateFilename = (member) => {
      const date = new Date().toISOString();
      const memberName = `${member.firstName}_${member.lastName}`.toLowerCase().replace(/\s+/g, "_");
      return `${memberName}_${date}.jpg`;
    };

    const uploadImageToFirebase = async (blob, member) => {
      const filename = generateFilename(member);
      const storageRef = fbRef(storage, `member_photos/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, blob);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };
    const fetchTeams = async () => {
      try {
        if (!auth.currentUser?.uid) {
          console.error("User UID not available.");
          return [];
        }
        // Only fetch teams that belong to the currently logged-in user
        const teamsCollection = collection(db, "teams");
        // check view all peremission
        userRole.value = await getUserRole(auth.currentUser);
        canViewAll.value = ROLES.TEAM.VIEW.includes(userRole.value?.toLowerCase());
        const q = query(teamsCollection, !canViewAll.value && where("ownerId", "==", auth.currentUser.uid));
        const qArray = query(teamsCollection, !canViewAll.value && where("ownerId", "array-contains", auth.currentUser.uid));
        const teamsSnapshot = await getDocs(q);
        const arraySnapshot = await getDocs(qArray);
        if (canViewAll.value) {
          return teamsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        } else {
          const mergedQuerySnapshot = [...teamsSnapshot.docs, ...arraySnapshot.docs];
          return mergedQuerySnapshot.map((doc) => ({ id: doc.id, ...doc.data() }));
        }
      } catch (error) {
        console.error("Failed to fetch teams:", error);
        return [];
      }
    };

    const createObjectURL = (blob) => {
      if (blob instanceof Blob) {
        return window.URL.createObjectURL(blob);
      }
      return "";
    };

    onMounted(async () => {
      if (props.teamId) {
        member.value.teamId = props.teamId;
      }

      if (auth.currentUser) {
        console.log("Welcome");
      } else {
        console.log("User not authenticated");
        return; // If no user is authenticated, then no need to fetch teams
      }

      try {
        teams.value = await fetchTeams();
      } catch (error) {
        console.error("Error fetching teams:", error);
      }

      try {
        titles.value = await fetchTitles();
        titles.value.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error("Error fetching titles:", error);
      }
    });

    const fetchStaffList = async () => {
      try {
        const staffCollection = collection(db, "members");
        const staffSnapshot = await getDocs(staffCollection);
        staffMembers.value = staffSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      } catch (error) {
        console.error("Failed to fetch staff:", error);
        // Handle the error appropriately, maybe show a user-facing message.
      }
    };

    const validatePhone = () => {
      const phoneRegex = /^[0-9\s\+\-\(\)]*$/;
      if (member.value.phone && !phoneRegex.test(member.value.phone)) {
        toast.error("Phone can only contain numbers and symbols!");
        return false;
      }
      return true;
    };

    const validateNameInput = (event) => {
      if (!nameRegex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s\-']/g, "");
        nameError.value = true;
        setTimeout(() => {
          nameError.value = false;
        }, 2000);
      }
    };

    // start from player

    const saveMember = async () => {
      if (!validatePhone()) return;

      if (!nameRegex.test(member.value.firstName) || !nameRegex.test(member.value.middleName) || !nameRegex.test(member.value.lastName)) {
        toast.error("Names can only contain text characters, hyphens, and apostrophes!");
        return;
      }

      if (croppedImageBlob.value) {
        try {
          const uploadedImageUrl = await uploadImageToFirebase(croppedImageBlob.value, member.value);
          member.value.photo = uploadedImageUrl;
          croppedImageBlob.value = null;
        } catch (error) {
          toast.error("Failed to upload image.");
          return;
        }
      }

      const type = member.value.id ? "update" : "add";
      await updateOrAddMember(type);
    };

    const updateOrAddMember = async (type) => {
      const membersCollection = collection(db, "members");

      if (type === "update") {
        const currentMemberDoc = doc(db, "members", member.value.id);
        await updateDoc(currentMemberDoc, member.value);
        toast.success("Member updated successfully!");
      } else if (type === "add") {
        if (member.value.email) {
          // check if email is already registered
          const profiles = collection(db, "profiles");
          const q = query(profiles, where("email", "==", member.value.email.toLowerCase()));
          const membersSnapshot = await getDocs(q);
          console.log(membersSnapshot.docs.length <= 0);
          if (membersSnapshot.docs.length <= 0) {
            // Create the user account without signing them in
            try {
              const { user } = await createUserWithEmailAndPassword(
                authAdmin,
                member.value.email,
                Math.random().toString(36).slice(-8) // generate random password
              );

              if (user) {
                // Create initial profile in Firestore for this user
                await setDoc(doc(db, "profiles", user.uid), {
                  firstName: member.value.firstName || "",
                  middleName: member.value.middleName || "",
                  lastName: member.value.lastName || "",
                  title: "",
                  email: user.email,
                  phone: member.value.phone || "",
                  biography: "",
                  role: "member",
                });
              }
            } catch (error) {
              if (error.code === "auth/email-already-in-use") {
                emailMsg.value = "This email is already registered. Please use a different email address.";
                return;
              } else {
                console.log("Error creating user:", error);
                emailMsg.value = "Invalid email.";
                return;
              }
            }
          }
        }
        try {
          await addDoc(membersCollection, {
            ...member.value,
            addedBy: auth.currentUser.uid,
          });
          // Create the user account without signing them in
          toast.success("Member added successfully!");
        } catch (error) {
          console.error("Error adding member:", error);
          toast.error("Error adding member: " + error.message);
        }
      }

      // Reset the member object
      member.value = {
        teamId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        titleId: "",
        phone: "",
        email: "",
        bio: "",
        photo: "",
      };

      emit("memberSaved");
      closeModal();
    };
    const closeModal = () => {
      emit("closeModal"); // this will signal the parent component
    };

    const handleCroppedImage = async (blob) => {
      try {
        const base64String = await blobToBase64(blob);
        member.value.photo = base64String;
      } catch (error) {
        console.error("Failed to convert blob to base64", error);
      }
    };

    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };
    const triggerImageSelection = () => {
      cropperModalOpen.value = true;
    };

    function setProcessingState(processing) {
      isProcessing.value = processing;
    }

    return {
      member,
      teams,
      closeModal,
      handleCroppedImage,
      triggerImageSelection,
      cropperModalOpen,
      isProcessing,
      setProcessingState,
      titles,
      validateNameInput,
      nameError,
      createObjectURL,
      croppedImageBlob,
      saveMember,
      selectedTitleId,
      emailMsg,
      saveMember,
      updateOrAddMember,
    };
  },
};
</script>
<style scoped>
.loading-spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
