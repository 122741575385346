// Private function to generate a 4-digit number from the unique ID
function generateFourDigitNumber(uniqueId) {
  // Convert the unique ID to a string
  const uniqueIdStr = uniqueId.toString();
  // Initialize a variable to hold the sum of the character codes
  let sum = 0;

  // Iterate over each character in the unique ID string
  for (let i = 0; i < uniqueIdStr.length; i++) {
    // Add the character code of the current character to the sum
    sum += uniqueIdStr.charCodeAt(i);
  }

  // Ensure the result is a 4-digit number by taking modulo 10000
  const fourDigitNumber = sum % 10000;

  // Return the 4-digit number as a string, padded with leading zeros if necessary
  return fourDigitNumber.toString().padStart(4, "0");
}

// Expose the public API

// Exporting the library for use in Node.js (if required)
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = {
    generateFourDigitNumber,
  };
}
