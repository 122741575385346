<template>
  <div class="container py-4 club">
    <TeamsList :ownerId="ownerId" />
  </div>
</template>
<script>
import TeamsList from "@/components/TeamsList.vue";
export default {
  name: "MyTeams",
  components: {
    TeamsList,
  },
  computed: {
    ownerId() {
      return this.$store?.state?.currentUser?.id || null;
    },
  },
};
</script>
