import { createStore } from "vuex";

export default createStore({
  state: {
    recaptchaVerified: true,
    user: { role: "" },
  },
  mutations: {
    setRecaptchaVerified(state, status) {
      state.recaptchaVerified = status;
    },
    setCurrentUser(state, data) {
      state.user = data;
    },
  },
  actions: {
    updateRecaptchaStatus({ commit }, status) {
      commit("setRecaptchaVerified", status);
    },
    updateCurrentUser({ commit }, data) {
      commit("setCurrentUser", data);
    },
  },
  getters: {
    isRecaptchaVerified: (state) => state.recaptchaVerified,
    getCurrentUserProfile: (state) => state.user?.role,
  },
});
