<template>
  <div class="container py-4 club">
    <ClubsList />
  </div>
</template>
<script>
import ClubsList from "@/components/ClubsList.vue";
export default {
  name: "MyClubs",
  components: {
    ClubsList,
  },
};
</script>
