<template>
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="row mb-2">
          <div class="col">
            <h2>Dashboard</h2>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="search-container d-flex">
              <div class="form-group position-relative w-100">
                <input
                  type="text"
                  class="form-control py-2"
                  v-model="searchTerm"
                  @input="onSearch"
                  @focus="isShowSearchOption = true"
                  @blur="isShowSearchOption = false"
                  placeholder="Search teams: enter text..."
                />
                <ul
                  v-if="searchResults.length"
                  class="z-1"
                >
                  <li
                    v-for="result in searchResults"
                    :key="result.id"
                    @click="goToTeam(result.id)"
                  >
                    {{ result.name }} <span v-if="result.type">{{ result.type }}</span>
                  </li>
                </ul>
              </div>
              <!-- <button class="btn btn-outline-primary btn-sm ms-2" @click="search">Search</button> -->
              <img
                src="../assets/icon/tube_spinner.svg"
                width="40"
                height="40"
                v-show="isSearchingTeam"
              />
            </div>
          </div>
        </div>
        <div v-if="userRole && userRole != 'recruiter'">
          <div class="card">
            <div class="card-body">
              <h4 class="mb-4">My Teams</h4>
              <div
                v-for="n in 6"
                v-if="isTeamLoadings && !teams.length"
              >
                <PreLoaderComponent heightPx="130px" />
              </div>
              <div
                class="card mb-2 card-list-decoration no-deco"
                v-if="teams.length"
                v-for="team in teams"
                :key="team.id"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2 col-sm-2 d-flex align-items-center">
                      <img
                        :src="team.clubLogo || '/images/club-logo-default-thumb.jpg'"
                        alt="Club Logo"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div class="col-md-8 col-sm-8 pb-2">
                      <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">
                        <strong
                          >{{ team?.leagueShortName }} {{ team.clubShortName }}
                          {{ team.state ? "(" + team.state + ")" : "" }}
                          {{ team.ageGroup }} - {{ team.gender }}</strong
                        >
                      </router-link>

                      <br />
                      <span class="mini-label">Team Alias: {{ team.name }}</span
                      ><br />
                      <span class="mini-label">Club Region: {{ team.regionName }}</span>
                    </div>

                    <div class="col-sm-2 col-md-2 d-flex-column align-items-center pb-2">
                      <div class="mini-label top">Player Count</div>
                      <div>
                        <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">{{ team.playerCount || 0 }} players</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!isTeamLoadings && !teams.length">
                <div class="alert alert-info">
                  <p>There are no teams in your account. Add a team to start</p>
                </div>
              </div>

              <div
                v-if="!loading"
                class="col-md-4 text-start"
              >
                <router-link
                  to="/add-team"
                  class="btn btn-primary"
                  >+ Add Team</router-link
                >
              </div>
            </div>
          </div>
          <!--Team in which the player added player added-->
          <div
            class="card mt-5"
            v-if="allTeamsInWhichUserAdded.length"
          >
            <div class="card-body">
              <h4 class="mb-4">Teams you are part of</h4>
              <!-- <div
              v-for="n in 6"
              v-if="isAllTeamInWhichUserAddedLoading && !allTeamsInWhichUserAdded.length"
            >
              <PreLoaderComponent heightPx="130px" />
            </div> -->

              <div
                class="card mb-2 card-list-decoration no-deco"
                v-for="team in allTeamsInWhichUserAdded"
                :key="team.id"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2 col-sm-2 d-flex align-items-center">
                      <img
                        :src="team.clubLogo || '/images/club-logo-default-thumb.jpg'"
                        alt="Club Logo"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div class="col-md-5 col-sm-10 pb-2">
                      <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">
                        <strong
                          >{{ team?.leagueShortName }} {{ team.clubShortName }}
                          {{ team.state ? "(" + team.state + ")" : "" }}
                          {{ team.ageGroup }} - {{ team.gender }}</strong
                        >
                      </router-link>

                      <br />
                      <span class="mini-label">Team Alias: {{ team.name }}</span
                      ><br />
                      <span class="mini-label">Club Region: {{ team.regionName }}</span>
                    </div>

                    <div class="col-sm-2 col-md-5 pb-2">
                      <div class="mini-label top text-align-end">Player Count</div>
                      <div class="text-align-end">
                        <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">{{ team.playerCount || 0 }} players</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="!isAllTeamInWhichUserAddedLoading && !allTeamsInWhichUserAdded.length">
              <div class="alert alert-info">
                <p>No Team</p>
              </div>
            </div> -->
            </div>
          </div>
        </div>
        <div v-else-if="userRole == 'recruiter'">
          <div class="card">
            <div class="card-body">
              <h4 class="mb-4">Recently Viewed Teams</h4>
              <div
                v-for="n in 6"
                v-if="isRecruiterSearchTeamsLoading && !recruiterSearchTeams.length"
              >
                <PreLoaderComponent heightPx="130px" />
              </div>
              <div
                class="card mb-2 card-list-decoration no-deco"
                v-if="recruiterSearchTeams.length"
                v-for="team in recruiterSearchTeams"
                :key="team.id"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2 col-sm-2 d-flex align-items-center">
                      <img
                        :src="team.clubLogo || '/images/club-logo-default-thumb.jpg'"
                        alt="Club Logo"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div class="col-md-8 col-sm-8 pb-2">
                      <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">
                        <strong
                          >{{ team?.leagueShortName }} {{ team.clubShortName }}
                          {{ team.state ? "(" + team.state + ")" : "" }}
                          {{ team.ageGroup }} - {{ team.gender }}</strong
                        >
                      </router-link>

                      <br />
                      <span class="mini-label">Team Alias: {{ team.name }}</span
                      ><br />
                      <span class="mini-label">Club Region: {{ team.regionName }}</span>
                    </div>

                    <div class="col-sm-2 col-md-2 d-flex-column align-items-center pb-2">
                      <div class="mini-label top">Player Count</div>
                      <div>
                        <router-link :to="{ name: 'TeamManage', params: { teamId: team.id } }">{{ team.playerCount || 0 }} players</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!isRecruiterSearchTeamsLoading && !recruiterSearchTeams.length">
                <div class="alert alert-info">
                  <p>No Search Result Found</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form
          v-if="loadingProfile && !requestdRoles.includes(profile.role)"
          class="pt-4 jumbotron bg-white mt-4 p-4 rounded box-shadow"
          @submit="sendRequest"
        >
          <div class="form-group">
            <div class="media-body pb-2 mb-0 small lh-125">
              <div class="d-flex justify-content-between align-items-center w-100">
                <img
                  v-if="profile.picture"
                  :src="profile.picture"
                  alt="avatar"
                  class="img-fluid rounded-circle me-1"
                  width="35"
                />
              </div>
              <strong
                v-if="profile?.email"
                class="text-gray-dark"
                >{{ profile?.email }}</strong
              >
              <span
                v-if="profile?.firstName || profile?.middleName || profile?.lastName"
                class="d-block mt-1"
                >Full name: {{ profile?.firstName && profile?.firstName }} {{ profile?.middleName && profile?.middleName }} {{ profile?.lastName && profile?.lastName }}</span
              >
              <span
                v-if="profile?.phone"
                class="d-block mt-1"
                >Phone: {{ profile?.phone }}</span
              >
            </div>
          </div>
          <div class="form-group pb-2">
            <label for="role">Request a role</label>
            <select
              class="form-control"
              id="role"
              required
              v-model="requestRole.role"
            >
              <option value="admin">Admin</option>
              <option value="teamowner">Team Owner</option>
            </select>
          </div>
          <div class="form-group pb-2">
            <label for="comment">Comment</label>
            <textarea
              class="form-control"
              id="comment"
              rows="3"
              v-model="requestRole.comment"
            ></textarea>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
          >
            Send Request
            <div
              class="spinner-border spinner-border-sm ms-2"
              role="status"
              v-if="requestLoading"
            ></div>
          </button>
        </form>
      </div>
      <div class="col-md-5">
        <img
          class="w-100"
          src="../assets/images/right-logo.png"
        />
      </div>
    </div>
    <!-- Modal for Deleting Team -->
    <ModalComponent
      v-model="showModal"
      header="Confirm Deleting the Team"
    >
      <template #default>
        <div>
          <p v-if="selectedTeam && selectedTeam.playerCount > 0">You cannot delete this team because it has associated players. Delete the players first before you can delete the team.</p>
          <p
            v-else
            class="text-center"
          >
            Are you sure you want to delete this team?
          </p>
        </div>
        <div>
          <button
            class="btn btn-sm btn-secondary-outline me-2"
            @click="showModal = false"
          >
            No, Cancel
          </button>
          <button
            class="btn btn-sm btn-danger"
            v-if="selectedTeam && selectedTeam.playerCount === 0"
            @click="deleteTeam"
          >
            Yes, Delete
          </button>
        </div>
      </template>
    </ModalComponent>
    <!-- Modal for Adding Player -->
    <ModalComponent
      v-model="showAddPlayerModal"
      header="Add Player to Team"
    >
      <PlayerFormComponent
        :teamId="selectedTeamId"
        @playerAdded="onPlayerAdded"
        @closeModal="handleCloseAddPlayerModal"
      />
    </ModalComponent>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, query, where, deleteDoc, addDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useRouter } from "vue-router";
import emailjs from "@emailjs/browser";
import PreLoaderComponent from "@/components/PreLoaderComponent.vue";
import PlayerFormComponent from "./PlayerFormComponent.vue";
import { useToast } from "vue-toastification";
import ROLES from "../utils/roles.json";
import ModalComponent from "./ModalComponent.vue";
import { getUserRole } from "../utils/auth";

export default {
  components: {
    PreLoaderComponent,
    ModalComponent,
    PlayerFormComponent,
  },
  setup() {
    const router = useRouter();
    const searchTerm = ref("");
    const searchResults = ref([]);
    const profile = ref({});
    const teams = ref([]);
    const recruiterSearchTeams = ref([]);
    const isRecruiterSearchTeamsLoading = ref(false);
    const allTeamsInWhichUserAdded = ref([]);
    const requestdRoles = ref(["admin", "teamowner", "recruiter"]);
    const loadingProfile = ref(false);
    const isTeamLoadings = ref(false);
    const isAllTeamInWhichUserAddedLoading = ref(false);
    const requestLoading = ref(false);
    const isSearchingTeam = ref(false);
    const isShowSearchOption = ref(false);
    const canAddPlayer = ref(false);
    const canDelete = ref(false);
    const canEdit = ref(false);
    const userId = auth.currentUser?.uid;
    const userRole = ref("");
    const selectedTeam = ref(null);
    const showModal = ref(false);
    const showAddPlayerModal = ref(false);
    const selectedTeamId = ref(null);
    const requestRole = ref({
      role: "",
      comment: "",
    });
    const emailJsConfig = ref({});

    onMounted(async () => {
      userRole.value = await getUserRole(auth.currentUser);
      if (userRole.value == "recruiter") {
        fetchRecruiterTeamsHistory();
      } else {
        fetchCurrentUserTeams();
        fetchAllTeamInWhichUserCurrentUserAdded();
      }

      try {
        const docRef = doc(db, "profiles", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          profile.value = docSnap.data();
          profile.value.email = auth.currentUser.email;
          //loadingProfile.value = true;
        } else {
          console.warn("Profile document does not exist");
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }

      // get the config from firebase
      const emailJsDoc = query(collection(db, "emailJs"), where("id", "==", "requestRoleConfig"));
      const emailJsSnapshot = await getDocs(emailJsDoc);
      emailJsSnapshot.forEach((doc) => {
        emailJsConfig.value = doc.data();
      });
    });

    function sendRequest(e) {
      e.preventDefault();
      const userUrl = window.location.origin + `/superadmin-dashboard/users/${auth.currentUser.uid}`;
      requestLoading.value = true;
      let request = {
        requestRole: requestRole.value.role,
        comment: requestRole.value.comment,
        fullName: profile.value.firstName + " " + profile.value.middleName + " " + profile.value.lastName,
        email: profile.value.email,
        userUrl: userUrl,
      };
      // send email
      emailjs.send(emailJsConfig.value.serviceID, emailJsConfig.value.templateID, request, emailJsConfig.value.publicKey).then(
        (response) => {
          // reset values
          requestRole.value = {
            role: "",
            comment: "",
          };
          requestLoading.value = false;
          const toast = useToast();
          toast.success("Request sent successfully, we will get back to you soon.");
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    }

    function checkIfUserIdMatchWithTeamOwn(userId, ownerId) {
      if (Array.isArray(ownerId)) {
        return ownerId.includes(userId);
      }
      return userId == ownerId;
    }

    function handleCloseAddPlayerModal() {
      showAddPlayerModal.value = false;
    }

    function showAddPlayerModalFunc(teamId) {
      selectedTeamId.value = teamId;
      showAddPlayerModal.value = true;
    }
    function showDeleteConfirmation(team) {
      selectedTeam.value = team;
      showModal.value = true;
    }
    async function deleteTeam() {
      if (!selectedTeam.value) return;

      try {
        await deleteDoc(doc(db, "teams", selectedTeam.value.id));
        showModal.value = false;
        fetchCurrentUserTeams();
        teams.value = [];
      } catch (error) {
        console.error("Error deleting team:", error);
      }
    }
    const onSearch = async () => {
      if (!searchTerm.value.length) {
        searchResults.value = [];
        return;
      }
      isSearchingTeam.value = true;
      try {
        // Search in teams
        let teamQ = query(collection(db, "teams"), where("shortName", ">=", searchTerm.value), where("shortName", "<=", searchTerm.value + "\uf8ff"));
        let teamSnapshot = await getDocs(teamQ);
        let teamResults = teamSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().shortName,
          type: "(team)",
        }));
        let teamfullName = query(collection(db, "teams"), where("name", ">=", searchTerm.value), where("name", "<=", searchTerm.value + "\uf8ff"));
        let teamSnapshotfull = await getDocs(teamfullName);
        let teamResultsfull = teamSnapshotfull.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().shortName,
          type: "(team)",
        }));

        // Search in clubs by name
        // let clubNameQ = query(
        //     collection(db, "clubs"),
        //     where("name", ">=", searchTerm.value),
        //     where("name", "<=", searchTerm.value + '\uf8ff')
        // );
        // let clubNameSnapshot = await getDocs(clubNameQ);
        // let clubNameResults = clubNameSnapshot.docs.map((doc) => ({
        //     id: doc.id,
        //     name: doc.data().name,
        //     type: "(club)"
        // }));

        // Search in clubs by shortName
        // let clubShortNameQ = query(
        //     collection(db, "clubs"),
        //     where("shortName", ">=", searchTerm.value),
        //     where("shortName", "<=", searchTerm.value + '\uf8ff')
        // );
        // let clubShortNameSnapshot = await getDocs(clubShortNameQ);
        // let clubShortNameResults = clubShortNameSnapshot.docs.map((doc) => ({
        //     id: doc.id,
        //     name: doc.data().shortName,
        //     type: "(club)"
        // }));

        // Merge the results
        isSearchingTeam.value = false;
        searchResults.value = [...teamResults, ...teamResultsfull];
      } catch (error) {
        isSearchingTeam.value = false;
        console.error("Error fetching results:", error);
      }
    };

    const goToTeam = async (teamId) => {
      router.push(`/TeamManage/${teamId}`);
      if (userRole.value == "recruiter") {
        await addToVisitedTeams(teamId);
      }
    };
    function onPlayerAdded() {
      showAddPlayerModal.value = false;
      fetchCurrentUserTeams();
    }
    const search = () => {
      if (searchTerm.value.length < 3) {
        searchResults.value = [];
        return;
      }
      router.push(`/my-teams?search=${searchTerm.value}`);
    };
    const fetchRecruiterTeamsHistory = async () => {
      isRecruiterSearchTeamsLoading.value = true;
      const stringQuery = query(collection(db, "recruiterSearchHistory"), where("userId", "==", userId));
      const stringQuerySnapshot = await getDocs(stringQuery);

      if (stringQuerySnapshot.size) {
        const data = stringQuerySnapshot.docs[0].data();
        if (data.search.length) {
          const teamPromises = data.search.map(async (id) => {
            const TeamSnapshot = await getDoc(doc(db, "teams", id));
            if (TeamSnapshot.exists()) {
              // Get count of players associated with this team
              const teamData = TeamSnapshot.data();
              const playerQuery = query(collection(db, "players"), where("teamId", "==", id));
              const playerSnapshots = await getDocs(playerQuery);
              teamData.playerCount = playerSnapshots.size;

              return {
                id: id,
                ...teamData,
              };
            }
          });
          const allTeams = await Promise.all(teamPromises);
          const teamDetailPromises = allTeams.map(async (teamData) => {
            if (teamData.club) {
              const clubSnapshot = await getDoc(doc(db, "clubs", teamData.club));
              if (clubSnapshot.exists()) {
                const clubData = clubSnapshot.data();
                teamData.clubShortName = clubData.shortName || "N/A";
                teamData.clubRegion = clubData.regionId || "N/A";
                teamData.clubLogo = clubData.logo || null;

                // Use the club's leagueId to get the league
                if (clubData.leagueId) {
                  clubData.leagueId.map(async (id) => {
                    const leagueSnapshot = await getDoc(doc(db, "leagues", id));
                    if (leagueSnapshot.exists()) {
                      const leagueData = leagueSnapshot.data();
                      teamData.leagueName = leagueData.name || "N/A";
                      teamData.leagueShortName = leagueData.shortName || "N/A";
                    }
                  });
                }

                // Use the club's regionId to get the region
                if (clubData.regionId) {
                  const regionSnapshot = await getDoc(doc(db, "regions", clubData.regionId));
                  if (regionSnapshot.exists()) {
                    const regionData = regionSnapshot.data();
                    teamData.regionName = regionData.name || "N/A";
                  }
                }
              }
            }
            if (teamData.state) {
              const stateSnapshot = await getDoc(doc(db, "states", teamData.state));
              if (stateSnapshot.exists()) {
                const stateData = stateSnapshot.data();
                teamData.state = stateData.state || "N/A"; // Override the team's state ID with the actual state name
              }
            }

            return {
              ...teamData,
            };
          });

          recruiterSearchTeams.value = await Promise.all(teamDetailPromises);
          isRecruiterSearchTeamsLoading.value = false;
        }
      } else {
        isRecruiterSearchTeamsLoading.value = false;
      }
    };
    const fetchCurrentUserTeams = async () => {
      isTeamLoadings.value = true;
      //let teamQuery = query(collection(db, "teams"), where("ownerId", "==", userId));
      // let write for the string query
      const stringQuery = query(collection(db, "teams"), where("ownerId", "==", userId));
      // let write for the ArrayQuery
      const arrayQuery = query(collection(db, "teams"), where("ownerId", "array-contains", userId));
      // Execute both queries concurrently
      const [stringQuerySnapshot, arrayQuerySnapshot] = await Promise.all([getDocs(stringQuery), getDocs(arrayQuery)]);

      // Merge the results of both queries
      const mergedQuerySnapshot = [...stringQuerySnapshot.docs, ...arrayQuerySnapshot.docs];
      //  const teamSnapshots = await getDocs(teamQuery);

      const teamPromises = mergedQuerySnapshot.map(async (teamSnapshot) => {
        const teamData = teamSnapshot.data();

        // Get count of players associated with this team
        const playerQuery = query(collection(db, "players"), where("teamId", "==", teamSnapshot.id));
        const playerSnapshots = await getDocs(playerQuery);
        teamData.playerCount = playerSnapshots.size;

        if (teamData.club) {
          const clubSnapshot = await getDoc(doc(db, "clubs", teamData.club));
          if (clubSnapshot.exists()) {
            const clubData = clubSnapshot.data();
            teamData.clubShortName = clubData.shortName || "N/A";
            teamData.clubRegion = clubData.regionId || "N/A";
            teamData.clubLogo = clubData.logo || null;

            // Use the club's leagueId to get the league
            if (clubData.leagueId) {
              clubData.leagueId.map(async (id) => {
                const leagueSnapshot = await getDoc(doc(db, "leagues", id));
                if (leagueSnapshot.exists()) {
                  const leagueData = leagueSnapshot.data();
                  teamData.leagueName = leagueData.name || "N/A";
                  teamData.leagueShortName = leagueData.shortName || "N/A";
                }
              });
            }

            // Use the club's regionId to get the region
            if (clubData.regionId) {
              const regionSnapshot = await getDoc(doc(db, "regions", clubData.regionId));
              if (regionSnapshot.exists()) {
                const regionData = regionSnapshot.data();
                teamData.regionName = regionData.name || "N/A";
              }
            }
          }
        }

        if (teamData.state) {
          const stateSnapshot = await getDoc(doc(db, "states", teamData.state));
          if (stateSnapshot.exists()) {
            const stateData = stateSnapshot.data();
            teamData.state = stateData.state || "N/A"; // Override the team's state ID with the actual state name
          }
        }

        return {
          id: teamSnapshot.id,
          ...teamData,
        };
      });

      teams.value = await Promise.all(teamPromises);
      isTeamLoadings.value = false;
    };

    const fetchAllTeamInWhichUserCurrentUserAdded = async () => {
      isAllTeamInWhichUserAddedLoading.value = true;
      const playersCollection = collection(db, "players");
      const queryPlayer = query(playersCollection, where("email", "==", auth.currentUser.email));
      const teamPlayersSnapshot = await getDocs(queryPlayer);

      if (teamPlayersSnapshot.size) {
        const teamPromises = teamPlayersSnapshot.docs.map(async (teamSnapshot) => {
          const playerData = teamSnapshot.data();
          let teamData;
          const docRef = doc(db, "teams", playerData.teamId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            teamData = docSnap.data();
            // Get count of players associated with this team
            const playerQuery = query(collection(db, "players"), where("teamId", "==", playerData.teamId));
            const playerSnapshots = await getDocs(playerQuery);
            teamData.playerCount = playerSnapshots.size;

            if (teamData.club) {
              const clubSnapshot = await getDoc(doc(db, "clubs", teamData.club));
              if (clubSnapshot.exists()) {
                const clubData = clubSnapshot.data();
                teamData.clubShortName = clubData.shortName || "N/A";
                teamData.clubRegion = clubData.regionId || "N/A";
                teamData.clubLogo = clubData.logo || null;

                // Use the club's leagueId to get the league
                if (clubData.leagueId) {
                  clubData.leagueId.map(async (id) => {
                    const leagueSnapshot = await getDoc(doc(db, "leagues", id));
                    if (leagueSnapshot.exists()) {
                      const leagueData = leagueSnapshot.data();
                      teamData.leagueName = leagueData.name || "N/A";
                      teamData.leagueShortName = leagueData.shortName || "N/A";
                    }
                  });
                }

                // Use the club's regionId to get the region
                if (clubData.regionId) {
                  const regionSnapshot = await getDoc(doc(db, "regions", clubData.regionId));
                  if (regionSnapshot.exists()) {
                    const regionData = regionSnapshot.data();
                    teamData.regionName = regionData.name || "N/A";
                  }
                }
              }
            }

            if (teamData.state) {
              const stateSnapshot = await getDoc(doc(db, "states", teamData.state));
              if (stateSnapshot.exists()) {
                const stateData = stateSnapshot.data();
                teamData.state = stateData.state || "N/A"; // Override the team's state ID with the actual state name
              }
            }
          }

          return {
            id: playerData.teamId,
            ...teamData,
          };
        });
        allTeamsInWhichUserAdded.value = await Promise.all(teamPromises);
        isAllTeamInWhichUserAddedLoading.value = false;
      }
    };

    // Function to add a team to the visited teams list
    const addToVisitedTeams = async (teamid) => {
      const stringQuery = query(collection(db, "recruiterSearchHistory"), where("userId", "==", userId));
      const stringQuerySnapshot = await getDocs(stringQuery);
      if (stringQuerySnapshot.size) {
        const data = stringQuerySnapshot.docs[0].data();
        const SearchArray = data.search;
        const updatedArray = addingSearchResultIntoArray(SearchArray, teamid);
        const currentRecruiterDoc = doc(db, "recruiterSearchHistory", stringQuerySnapshot.docs[0].id);
        await updateDoc(currentRecruiterDoc, {
          search: [...updatedArray],
          userId: userId,
        });
      } else if (recruiterSearchTeams.value.length == 0) {
        const docRef = await addDoc(collection(db, "recruiterSearchHistory"), {
          search: [teamid],
          userId: userId,
        });
      }
    };
    const addingSearchResultIntoArray = (existingArray, teamID) => {
      const index = existingArray.indexOf(teamID);
      if (index !== -1) {
        // If the team is already in the list, remove it from its current position
        existingArray.splice(index, 1);
      }
      // Add the team to the beginning of the list
      existingArray.unshift(teamID);

      // Keep only the 10 most recent visited teams
      if (existingArray.length > 10) {
        existingArray.pop();
      }

      return existingArray;
    };
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        userRole.value = await getUserRole(user);
        canEdit.value = ROLES.TEAM.EDIT.includes(userRole.value?.toLowerCase());
        canDelete.value = ROLES.TEAM.DELETE.includes(userRole.value?.toLowerCase());
        canAddPlayer.value = ROLES.PLAYER.CREATE.includes(userRole.value?.toLowerCase());
      }
    });
    return {
      searchTerm,
      searchResults,
      onSearch,
      goToTeam,
      sendRequest,
      search,
      profile,
      requestRole,
      requestdRoles,
      loadingProfile,
      requestLoading,
      teams,
      isSearchingTeam,
      isShowSearchOption,
      isTeamLoadings,
      userId,
      canAddPlayer,
      canDelete,
      canEdit,
      userRole,
      showModal,
      selectedTeam,
      showDeleteConfirmation,
      deleteTeam,
      showAddPlayerModalFunc,
      showAddPlayerModal,
      selectedTeamId,
      onPlayerAdded,
      handleCloseAddPlayerModal,
      checkIfUserIdMatchWithTeamOwn,
      allTeamsInWhichUserAdded,
      isAllTeamInWhichUserAddedLoading,
      recruiterSearchTeams,
      isRecruiterSearchTeamsLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.search-container {
  position: relative;
}

.z1 {
  z-index: 1 !important;
}

ul {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  width: 100%;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 8px;
  cursor: pointer;
}

li:hover {
  background: #f7f7f7;
}

$gray-60: #777;

.no-deco {
  a {
    text-decoration: none;
  }

  .icon-link {
    color: #444 !important;
  }
}

.card-list-decoration {
  transition: all ease-in-out 0.5s;
  background-color: #f6f6f6;

  &:hover {
    background-color: #dae3eb;
    box-shadow: 1px 1px 4px #b7c7cd;
  }
}

.mini-label {
  color: $gray-60;
  font-size: 0.8rem;
}
.cursor {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed !important;
}
.text-align-end {
  text-align: end;
}
</style>
