import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase/index";
import { doc, getDoc } from "firebase/firestore";
import ROLES from "./roles.json";

export function checkAuth() {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(async (user) => {
        resolve({status: user && user.emailVerified, role: await getUserRole(user)});
    });
  });
}

export async function getUserRole(user) {
  if (user) {
    const docRef = doc(db, "profiles", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let { role } = docSnap.data();
      role = role?.toLowerCase();
      role == "" && (role = ROLES.DEFAULT_ROLE);
      return role;
    } else {
      return null
    }
  }
}
