<template>
  <div class="card">
    <div class="card-body bg-accent-800 rounded">
      <form
        class="row strong-label"
        @submit.prevent="addClub"
      >
        <div class="col-md-4">
          <div class="card">
            <div class="card-body text-center">
              <!-- Static Image -->
              <img
                v-if="!club.logo && !cropperModalOpen && !croppedImage"
                src="images/club-logo-default.jpg"
                class="img-fluid"
              />

              <!-- Saved/Cropped Image -->
              <img
                v-if="club.logo && !cropperModalOpen && !croppedImage"
                :src="club.logo"
                class="img-fluid"
              />

              <!-- Newly Cropped Image -->
              <img
                v-if="croppedImage && !cropperModalOpen"
                :src="croppedImage"
                class="img-fluid"
              />

              <!-- Cropper Component -->
              <CropperComponent
                :modalOpen="cropperModalOpen"
                @cropperOpened="cropperModalOpen = true"
                @blobReady="handleCroppedImage"
                @imageUploaded="handleImageUpload"
                @imageBeingCropped="setProcessingState(true)"
                @imageCropped="setProcessingState(false)"
                @cropperClosed="cropperModalOpen = false"
                :stencil-size="{ width: 300, height: 300 }"
                :stencil-props="{
                  handlers: {},
                  movable: false,
                  resizable: false,
                  aspectRatio: 1,
                }"
                image-restriction="stencil"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card bg-transparent text-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>League <span class="required">*</span></label>
                  <VueMultiselect
                    :multiple="true"
                    :options="leagues"
                    v-model="selectedValues"
                    @select="handleSelectedItems"
                    label="name"
                    track-by="id"
                    placeholder="Select league"
                    :loading="isLeaguesLoading"
                  >
                    <template #selection="{ values }">
                      <span
                        v-for="(data, index) in values"
                        class="multiselect__tag"
                      >
                        <span> {{ data.shortName }}&nbsp; </span>
                        <i
                          @click.stop="removeItem(index)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </VueMultiselect>
                </div>
                <div class="col-md-12 mb-4">
                  <label>Region <span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="club.regionId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a region
                    </option>
                    <option
                      v-for="region in regions"
                      :key="region.id"
                      :value="region.id"
                    >
                      {{ region.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>Club Name<span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="club.name"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Club Short Name<span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="club.shortName"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>State (Where is the club located?) <span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="club.stateId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a state
                    </option>
                    <option
                      v-for="state in states"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }} - ({{ state.state }})
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-4">
                  <label>About the Club</label>
                  <textarea
                    class="form-control"
                    type="text"
                    v-model="club.description"
                  />
                </div>
              </div>
              <div class="col-md-12 mb-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="isImageBeingProcessed"
                >
                  Save
                </button>

                <button
                  class="btn btn-outline-light"
                  type="button"
                  @click="closeModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router"; // Ensure this line exists
import { auth, db, storage } from "../firebase";
import { collection, addDoc, getDocs, updateDoc, doc } from "firebase/firestore";
import { ref as fbRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CropperComponent from "./CropperComponent.vue";
import { useToast } from "vue-toastification";
import VueMultiselect from "vue-multiselect";
export default {
  components: {
    CropperComponent,
    VueMultiselect,
  },
  props: ["initialClub", "isAddingTeam"],

  setup(props, { emit }) {
    const router = useRouter();
    const toast = useToast();
    const initialSelectedItems = ref([]);

    const club = ref({
      name: "",
      shortName: "",
      description: "",
      logo: null,
      leagueId: [],
      regionId: "",
      stateId: "",
    });
    const isLeaguesLoading = ref(false);
    const selectedValues = ref(null);
    const leagues = ref([]);
    const regions = ref([]);
    const states = ref([]);

    onMounted(async () => {
      if (props.initialClub) {
        club.value = { ...props.initialClub };
      }

      try {
        leagues.value = await fetchLeagues();
        regions.value = await fetchRegions();
        states.value = await fetchStates();
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      //Fetching leagues and regions upon mounting the component
      leagues.value = await fetchLeagues();
      regions.value = await fetchRegions();
      states.value = await fetchStates();
    });

    const handleSelectedItems = () => {
      club.value.leagueId = selectedValues.value.map((item) => item.id);
    };

    const fetchLeagues = async () => {
      isLeaguesLoading.value = true;
      const leaguesCollection = collection(db, "leagues");
      const leaguesSnapshot = await getDocs(leaguesCollection);
      let leaguesList = leaguesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort leagues alphabetically by their name
      leaguesList.sort((a, b) => a.name.localeCompare(b.name));
      isLeaguesLoading.value = false;
      return leaguesList;
    };

    const fetchRegions = async () => {
      const regionsCollection = collection(db, "regions");
      const regionsSnapshot = await getDocs(regionsCollection);
      let regionsList = regionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort regions alphabetically by their name
      regionsList.sort((a, b) => a.name.localeCompare(b.name));
      return regionsList;
    };
    const removeItem = (index) => {
      selectedValues.value.splice(index, 1);
    };
    const fetchStates = async () => {
      const statesCollection = collection(db, "states");
      const statesSnapshot = await getDocs(statesCollection);
      let statesList = statesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort states alphabetically by their name
      statesList.sort((a, b) => a.name.localeCompare(b.name));
      return statesList;
    };

    const croppedImageBlob = ref(null);
    const cropperModalOpen = ref(false);
    const isImageBeingProcessed = ref(false);
    const croppedImage = ref(null);
    // Methods
    const closeModal = () => emit("closeModal");

    const setProcessingState = (state) => {
      isImageBeingProcessed.value = state;
    };

    const handleImageUpload = () => {
      isImageBeingProcessed.value = true;
    };

    const handleCroppedImage = async (blob) => {
      try {
        const base64String = await blobToBase64(blob);
        club.value.logo = base64String;
      } catch (error) {
        console.error("Failed to convert image", error);
      }
    };

    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    //ADD club

    const addClub = async () => {
      const clubs = await fetchClubs();

      const duplicateNameClub = clubs.find((c) => c.name === club.value.name && (!props.initialClub || c.id !== props.initialClub.id));
      const duplicateShortNameClub = clubs.find((c) => c.shortName === club.value.shortName && (!props.initialClub || c.id !== props.initialClub.id));
      const duplicateRegion = clubs.find((c) => c.regionId === club.value.regionId && (!props.initialClub || c.id !== props.initialClub.id));
      if (props.isAddingTeam && duplicateRegion && duplicateNameClub && duplicateShortNameClub) {
        // emit("clubAddRejection");
        toast.error("A club with the same name already exists in this region. Close this form and search again.");
        return;
      }

      if (duplicateNameClub) {
        toast.error("This club name already exists.");
        return;
      }

      if (!props.isAddingTeam && duplicateShortNameClub) {
        toast.error("This club short name already exists.");
        return;
      }

      if (croppedImageBlob.value) {
        club.value.logo = await uploadImage(croppedImageBlob.value);
      }

      // Add checks for league and region if needed
      if (!club.value.leagueId) {
        toast.error("Please select a league.");
        return;
      }
      if (!club.value.regionId) {
        toast.error("Please select a region.");
        return;
      }

      saveClub();
    };

    const uploadImage = async (blob) => {
      const storageRef = fbRef(storage, `club_logos/${auth.currentUser.uid}_${new Date().getTime()}`);
      const uploadTask = uploadBytesResumable(storageRef, blob);

      return new Promise((resolve, reject) => {
        uploadTask.on("state_changed", null, reject, async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        });
      });
    };

    const fetchClubs = async () => {
      const clubsCollection = collection(db, "clubs");
      const clubsSnapshot = await getDocs(clubsCollection);
      return clubsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })); // Include the id in the returned data
    };
    const customLabelTag = (data) => {
      return `${data.name} - (${data.shortName})`;
    };
    /*onst club = ref({
      name: "",
      shortName: "",
      description: "",
      logo: null,
      leagueId: [],
      regionId: "",
      stateId: "",
    }); */

    const saveClub = async () => {
      if (croppedImageBlob.value) {
        club.value.logo = await uploadImage(croppedImageBlob.value);
      }

      const clubsCollection = collection(db, "clubs");

      if (props.initialClub) {
        // Updating existing club
        const clubDoc = doc(db, "clubs", props.initialClub.id);
        await updateDoc(clubDoc, club.value);
        toast.success("club updated successfully!");
      } else {
        // Adding a new club
        await addDoc(clubsCollection, {
          ...club.value,
          addedBy: auth.currentUser.uid,
        });
        !props.isAddingTeam && toast.success("club added successfully!");
        props.isAddingTeam && toast.success("The Club was successfully added to the list");
        club.value = { name: "", shortName: "", description: "", logo: null };
      }
      emit("clubSaved");
    };

    return {
      club,
      leagues,
      regions,
      states,
      addClub,
      handleCroppedImage,
      handleSelectedItems,
      handleImageUpload,
      cropperModalOpen,
      closeModal,
      isImageBeingProcessed,
      setProcessingState,
      croppedImage,
      initialSelectedItems,
      selectedValues,
      customLabelTag,
      removeItem,
      isLeaguesLoading,
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
