<template>
  <div class="container mt-5">
    <h3 class="mb-4">Contact Us</h3>
    <form @submit.prevent="submitForm">
      <!-- Full Name -->
      <div class="mb-3">
        <label
          for="fullName"
          class="form-label"
          >Full Name</label
        >
        <input
          type="text"
          class="form-control"
          v-model="fullName"
          required
        />
      </div>

      <!-- Email Address -->
      <div class="mb-3">
        <label
          for="email"
          class="form-label"
          >Email Address</label
        >
        <input
          type="email"
          class="form-control"
          v-model="email"
          required
        />
      </div>

      <!-- Phone Number -->
      <div class="mb-3">
        <label
          for="phoneNumber"
          class="form-label"
          >Phone Number</label
        >
        <input
          type="tel"
          class="form-control"
          v-model="phoneNumber"
        />
      </div>

      <!-- Request Subject Dropdown -->
      <div class="mb-3">
        <label
          for="requestSubject"
          class="form-label"
          >Subject of your message</label
        >
        <select
          class="form-select"
          v-model="requestSubject"
          required
        >
          <option
            value=""
            disabled
          >
            Select a Subject
          </option>
          <option
            v-for="subject in requestSubjects"
            :key="subject"
          >
            {{ subject }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label
          for="requestMessage"
          class="form-label"
          >Message</label
        >
        <textarea
          class="form-control"
          placeholder="Leave a comment here"
          v-model="requestMessage"
        >
        </textarea>
      </div>
      <p
        class="error alert alert-danger mb-3 d-flex justify-content-between align-items-center"
        v-if="errorMsg"
        role="alert"
      >
        {{ errorMsg }}
      </p>
      <RecaptchaComponent />
      <br />
      <!-- Submit Button -->
      <button
        type="submit"
        class="btn btn-primary"
      >
        Submit Request
        <div
          class="spinner-border spinner-border-sm ms-2"
          role="status"
          v-if="requestLoading"
        ></div>
      </button>
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import { collection, getDocs, query, where } from "firebase/firestore";
import RecaptchaComponent from "@/components/RecaptchaComponent.vue";
import { useStore } from "vuex";
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useToast } from "vue-toastification";
import { db } from "../firebase";

// EmailJS

export default {
  components: {
    RecaptchaComponent,
  },

  setup() {
    const fullName = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const requestSubject = ref("");
    const requestMessage = ref("");
    const requestSubjects = ["General", "Request Role Change", "Team Issue", "Login Issue", "Club Issue", "Account Issue", "Player Issue", "Technical Support", "Advertising", "Partnership"];
    const requestLoading = ref(false);
    const emailJsConfig = ref({});
    const errorMsg = ref("");
    const submitForm = () => {
      sendEmail();
    };
    const store = useStore();
    // reset reCAPTCHA when component is unmounted
    onMounted(async () => {
      // get the config from firebase
      const emailJsDoc = query(collection(db, "emailJs"), where("id", "==", "requestConfig"));
      store.dispatch("updateRecaptchaStatus", false); // Update reCAPTCHA status in the store
      const emailJsSnapshot = await getDocs(emailJsDoc);
      emailJsSnapshot.forEach((doc) => {
        emailJsConfig.value = doc.data();
      });
    });

    onUnmounted(() => {
      store.dispatch("updateRecaptchaStatus", false); // Update reCAPTCHA status in the store
    });
    const sendEmail = () => {
      requestLoading.value = true;
      if (!store.getters.isRecaptchaVerified) {
        errorMsg.value = "Please complete the reCAPTCHA challenge.";
        requestLoading.value = false;
        return;
      }
      const templateParams = {
        request_name: fullName.value,
        request_email: email.value,
        email_to: "info@teamsheetpro.com",
        request_phone: phoneNumber.value,
        request_message: requestMessage.value,
        request_subject: requestSubject.value,
      };

      emailjs.send("service_SheetProX", "template_gnrvr8b", templateParams, "S6E9Jwpf9inCzK0X5").then(
        (response) => {
          // reset values
          fullName.value = "";
          email.value = "";
          phoneNumber.value = "";
          requestSubject.value = "";
          requestMessage.value = "";
          requestLoading.value = false;
          const toast = useToast();
          toast.success("Request sent successfully, we will get back to you soon.");
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    };

    return {
      fullName,
      email,
      phoneNumber,
      requestSubject,
      requestSubjects,
      requestLoading,
      submitForm,
      errorMsg,
      requestMessage,
    };
  },
};
</script>
