<template>
  <div class="container-fluid bg-footer">
    <div class="row py-4">
      <div class="col text-center">
        <div class="d-flex justify-content-center">
          <div class="item text-light px-2 text-small">
            <router-link to="/about-us" class="footer-link" active-class="active"
              >About Us</router-link
            >
          </div>
          <div class="item text-light px-2 text-small">
            <router-link to="/privacy-policy" class="footer-link" active-class="active"
              >Privacy Policy</router-link
            >
          </div>
          <div class="item text-light px-2 text-small">
            <router-link to="/contact" class="footer-link" active-class="active"
              >Contact Us</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row py-2 bg-copyright">
      <div class="col text-lighter text-center">
        <small>&copy; Copyright 2024 Team Sheet Pro</small>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    
    const router = useRouter();
    const store = useStore(); 

    return {
   
    };
  },
};
</script>


<style lang="scss" scoped>
$accent-800: #072f56;
.bg-footer {
  background-color: $accent-800;
  a {
    color: lighten($accent-800, 60%);
  }
}
.bg-copyright {
  background-color: darken($accent-800, 6%);
}
.text-lighter {
  color: lighten($accent-800, 40%);
}
</style>
