<template>
  <div class="card px-0 container">
    <div class="card-body bg-accent-800 rounded">
      <form
        @submit.prevent="saveEvent"
        class="row strong-label"
      >
        <div class="col-md-12">
          <div class="card bg-transparent text-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>This Event is for the team <span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="teamEvent.teamId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a Team
                    </option>
                    <option
                      v-for="team in teams"
                      :key="team.id"
                      :value="team.id"
                    >
                      {{ team.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-4">
                  <label>Event Name <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="teamEvent.name"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Event Date <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="teamEvent.date"
                    required
                  />
                </div>

                <div class="col-md-12 mb-4">
                  <label>Event Location <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="teamEvent.location"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Event Details</label>
                  <textarea
                    class="form-control"
                    v-model="teamEvent.description"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 mb-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="isProcessing"
                >
                  Save
                </button>

                <button
                  class="btn btn-outline-light"
                  type="button"
                  @click="closeModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { auth, db, storage } from "../firebase";
import { collection, addDoc, getDocs, updateDoc, setDoc, doc, query, where } from "firebase/firestore";
import { useToast } from "vue-toastification";
import { getUserRole } from "../utils/auth";
import ROLES from "../utils/roles.json";
export default {
  props: {
    initialEvent: {
      type: Object,
      default: () => ({}),
    },
    teamId: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const isProcessing = ref(false);
    const toast = useToast();
    const userRole = ref("");
    const canViewAll = ref(false);
    const teamEvent = ref(
      props.initialEvent || {
        teamId: "",
        name: "",
        date: "",
        time: "",
        location: "",
        description: "",
      }
    );
    const teams = ref([]);

    watch(
      () => props.teamId,
      (newTeamId) => {
        teamEvent.value.teamId = newTeamId;
      }
    );

    watch(
      () => props.initialEvent,
      (newEvent, oldEvent) => {
        if (newEvent && (!oldEvent || newEvent.id !== oldEvent.id)) {
          teamEvent.value = { ...newEvent };
        }
      },
      { immediate: true }
    );

    const fetchTeams = async () => {
      try {
        if (!auth.currentUser?.uid) {
          console.error("User UID not available.");
          return [];
        }

        // Only fetch teams that belong to the currently logged-in user
        const teamsCollection = collection(db, "teams");
        // check view all peremission
        userRole.value = await getUserRole(auth.currentUser);
        canViewAll.value = ROLES.TEAM.VIEW.includes(userRole.value?.toLowerCase());
        const q = query(teamsCollection, !canViewAll.value && where("ownerId", "==", auth.currentUser.uid));
        const qArray = query(teamsCollection, !canViewAll.value && where("ownerId", "array-contains", auth.currentUser.uid));
        const teamsSnapshot = await getDocs(q);
        const arraySnapshot = await getDocs(qArray);
        if (canViewAll.value) {
          return teamsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        } else {
          const mergedQuerySnapshot = [...teamsSnapshot.docs, ...arraySnapshot.docs];
          return mergedQuerySnapshot.map((doc) => ({ id: doc.id, ...doc.data() }));
        }
      } catch (error) {
        console.error("Failed to fetch teams:", error);
        return [];
      }
    };

    onMounted(async () => {
      if (props.teamId) {
        teamEvent.value.teamId = props.teamId;
      }

      if (auth.currentUser) {
        // console.log("Welcome");
      } else {
        // console.log("User not authenticated");
        return; // If no user is authenticated, then no need to fetch teams
      }

      try {
        // console.log("Current User UID:", auth.currentUser?.uid);
        teams.value = await fetchTeams();
        // console.log("Fetched teams:", teams.value);
      } catch (error) {
        // console.error("Error fetching teams:", error);
      }
      teams.value = await fetchTeams();
    });

    const saveEvent = async () => {
      if (!teamEvent.value.name || !teamEvent.value.teamId || !teamEvent.value.location) {
        toast.error("Please fill all required fields");
        return;
      }

      const type = teamEvent.value.id ? "update" : "add";
      await updateOrAddEvent(type);
    };

    const updateOrAddEvent = async (type) => {
      const teamEventsCollection = collection(db, "teamEvents");

      if (type === "update" && teamEvent.value.id) {
        try {
          const currentEventDoc = doc(db, "teamEvents", teamEvent.value.id);
          await setDoc(currentEventDoc, teamEvent.value, { merge: true });
          toast.success("Event updated successfully!");
        } catch (error) {
          console.error("Error updating event:", error);
          toast.error("Error updating event: " + error.message);
        }
      } else if (type === "add") {
        const { id, ...eventWithoutId } = teamEvent.value;
        try {
          await addDoc(teamEventsCollection, {
            ...eventWithoutId,
            description: teamEvent.value.description || null, // Ensure description is saved even if it's null
            addedBy: auth.currentUser.uid,
          });
          toast.success("Event added successfully!");
          emit("eventSaved");
        } catch (error) {
          console.error("Error adding event:", error);
          toast.error("Error adding event: " + error.message);
        }
      }

      // Reset the event object
      teamEvent.value = {
        teamId: "",
        name: "",
        date: "",
        time: "",
        location: "",
        description: "",
      };

      emit("eventSaved");
      closeModal();
    };

    const closeModal = () => {
      emit("closeModal"); // this will signal the parent component
    };

    function setProcessingState(processing) {
      isProcessing.value = processing;
    }

    return {
      teamEvent,
      teams,
      closeModal,
      isProcessing,
      setProcessingState,
      saveEvent,
      updateOrAddEvent,
    };
  },
};
</script>
<style scoped lang="scss">
.loading-spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
