<template>
  <div class="col p-4">
    <h3 class="mb-4">Users</h3>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span
          class="input-group-text"
          id="basic-addon1"
        >
          <img
            src="../assets/icon/search.svg"
            width="20"
            height="22"
        /></span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Search"
        aria-label="Username"
        v-model="searchQuery"
        @input="searchTeam"
        aria-describedby="basic-addon1"
      />
    </div>
    <div class="mb-2">
      <small class="text-muted">Total Users: {{ totalNumberOfRows }}</small>
    </div>
    <div class="row">
      <div class="col table-responsive">
        <table class="table table-sm table-hover table-striped">
          <thead class="table-header">
            <tr>
              <th
                scope="col-1"
                class="fs-7 py-2 px-3"
              >
                Date Created
              </th>
              <th
                scope="col-3"
                class="fs-7 py-2 px-3"
              >
                Email Address
              </th>
              <th
                scope="col-2"
                class="fs-7 py-2 px-3"
              >
                Role
              </th>
              <th
                scope="col-3"
                class="fs-7 py-2 px-3"
              >
                First Name
              </th>
              <th
                scope="col-2"
                class="fs-7 py-2 px-3"
              >
                Last Name
              </th>
              <th
                scope="col-2"
                class="fs-7 py-2 px-3"
              >
                Teams
              </th>
              <th
                scope="col-1"
                class="fs-7 py-2 ps-2"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in paginatedProfiles"
              :key="user.id"
            >
              <td
                scope="col-1"
                class="fs-7 py-2 px-3"
              >
                {{ formatDate(user.createdAt) }}
              </td>
              <td
                scope="col-3"
                class="fs-7 py-2 px-3"
              >
                <router-link
                  :to="`users/${user.id}`"
                  class="btn btn-sm btn-link"
                  >{{ user.email }}</router-link
                >
              </td>
              <td
                scope="col-2"
                class="fs-7 py-2 px-3"
              >
                {{ user.role }}
              </td>
              <td
                scope="col-3"
                class="fs-7 py-2 px-3"
              >
                {{ user.firstName }}
              </td>
              <td
                scope="col-2"
                class="fs-7 py-2 p-3"
              >
                {{ user.lastName }}
              </td>
              <td
                scope="col-2"
                class="fs-7 py-2 p-3"
              >
                <div v-if="user.teams.length">
                  <router-link :to="{ name: 'MyTeams', params: { team: user.id } }">{{ user.teams.length }}</router-link>
                </div>
                <div v-else>-</div>
              </td>
              <td
                scope="col-1"
                class="fs-7 py-2 ps-3"
              >
                <button
                  class="btn btn-sm btn-link dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                </button>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <router-link
                      :to="`users/${user.id}`"
                      class="dropdown-item"
                      >Edit</router-link
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.prevent="openRoleModal(user)"
                      >Assign Role</a
                    >
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      class="text-danger dropdown-item"
                      href="#"
                      @click.prevent="openDeleteUserModal(user)"
                      >Delete</a
                    >
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card border-light">
          <div class="card-body d-flex">
            <div class="">
              <small class="text-muted">Total Users: {{ totalNumberOfRows }}</small>
            </div>
            <div
              class="ms-auto"
              v-if="totalNumberOfRows > 50"
            >
              <nav aria-label="...">
                <ul class="pagination mb-0">
                  <li
                    class="page-item"
                    :class="[selectedPaginationPage == 1 ? 'disabled not-allowed' : '']"
                    @click.stop="handlePaginationButtonClick('pre')"
                  >
                    <a
                      class="page-link cursor"
                      href="#"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      Previous
                    </a>
                  </li>
                  <li
                    class="page-item"
                    v-for="n in paginationPages"
                    :key="n"
                    :class="[selectedPaginationPage == n ? 'active' : '']"
                    @click.stop="onChangePagination(n)"
                  >
                    <a class="page-link cursor">{{ n }}</a>
                  </li>
                  <li
                    class="page-item"
                    :class="[selectedPaginationPage == paginationPages ? 'disabled not-allowed' : '']"
                    @click.stop="handlePaginationButtonClick('next')"
                  >
                    <a class="page-link cursor">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Update Role Modal -->
  <ModalComponent
    v-model="showAssignRole"
    header="Assign Role"
  >
    <div class="card px-0 container">
      <div class="card-body bg-accent-800 rounded">
        <form
          @submit.prevent="updateRole"
          class="row strong-label"
        >
          <div class="col-md-12">
            <div class="card bg-transparent text-white">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <label>Select Role <span class="required">*</span></label>
                    <select
                      class="form-control"
                      v-model="selectedRole"
                      required
                    >
                      <option
                        disabled
                        value=""
                      >
                        Select a Role
                      </option>
                      <option
                        v-for="role in roles"
                        :key="role"
                        :value="role"
                      >
                        {{ role }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-12 mb-4">
                    <button
                      class="btn btn-primary me-2"
                      type="submit"
                      :disabled="isProcessing"
                    >
                      <span v-if="isProcessing">Loading...</span>
                      <span v-else>Save</span>
                    </button>

                    <button
                      class="btn btn-outline-light"
                      type="button"
                      :disabled="isProcessing"
                      @click="closeRoleModal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ModalComponent>
  <ModalComponent
    v-model="showDeleteUserModal"
    header="Delete Event"
  >
    <div>
      {{ deleteModelMessage }}
      <div class="mt-3 d-flex justify-content-end">
        <button
          @click="confirmDeleteUser"
          class="btn btn-danger me-2"
        >
          <span v-if="isProcessing">Loading...</span>
          <span v-else>Yes</span>
        </button>
        <button
          @click="cancelDeleteUser"
          class="btn btn-secondary"
          :disabled="isProcessing"
        >
          No
        </button>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { db } from "../firebase";
import ModalComponent from "./ModalComponent.vue";
import { useToast } from "vue-toastification";
import ROLES from "../utils/roles.json";

const toast = useToast();

import { collection, deleteDoc, doc, getDocs, updateDoc, query, limit, startAfter, endBefore, where } from "firebase/firestore";

export default {
  components: { ModalComponent },
  setup() {
    const profiles = ref([]);
    const filter = ref([]);
    const searchQuery = ref("");
    const totalNumberOfRows = ref(0);
    const paginationPages = ref(1);
    const selectedPaginationPage = ref(1);
    const showAssignRole = ref(false);
    const showDeleteUserModal = ref(false);
    const paginationSize = 50; // Number of items per page
    const roleUser = ref("");
    const deleteModelMessage = ref("Are you sure you want to delete this user?");
    const selectedRole = ref("");
    const isProcessing = ref("");
    const roles = ref(["admin", "recruiter", "subscriber"]);
    const fetchData = async () => {
      try {
        const profilesSnapshot = await getDocs(collection(db, "profiles"));
        const promiseTeams = profilesSnapshot.docs.map(async (doc) => {
          let data = await checkTheUserHasTeams({
            id: doc.id,
            ...doc.data(),
          });
          return {
            id: doc.id,
            teams: data,
            ...doc.data(),
          };
        });

        profiles.value = await Promise.all(promiseTeams);
        profiles.value = sortByDateDescending(profiles.value);
        totalNumberOfRows.value = profilesSnapshot.size;
        paginationPages.value = Math.ceil(totalNumberOfRows.value / paginationSize);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const sortByDateDescending = (dataArray) => {
      return dataArray.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;
        const dateA = new Date(a.createdAt.seconds * 1000);
        const dateB = new Date(b.createdAt.seconds * 1000);
        return dateB - dateA;
      });
    };
    onMounted(async () => {
      await fetchData();
    });
    function searchTeam() {
      filter.value = profiles.value.filter((profile) => {
        return profile.email.toLowerCase().includes(searchQuery?.value?.toLowerCase()) || profile.firstName.toLowerCase().includes(searchQuery?.value?.toLowerCase()) || profile.lastName.toLowerCase().includes(searchQuery?.value?.toLowerCase());
      });
    }
    const paginatedProfiles = computed(() => {
      const startIndex = (selectedPaginationPage.value - 1) * paginationSize;
      const endIndex = startIndex + paginationSize;
      return filter.value.length ? filter.value.slice(startIndex, endIndex) : profiles.value.slice(startIndex, endIndex);
    });

    const openRoleModal = (user) => {
      roleUser.value = user;
      selectedRole.value = user.role;
      showAssignRole.value = true;
    };
    const closeRoleModal = () => {
      roleUser.value = null;
      selectedRole.value = null;
      showAssignRole.value = false;
    };
    const openDeleteUserModal = (user) => {
      setDeleteMessage(user);
    };
    const setDeleteMessage = async (user) => {
      let data = await checkTheUserHasTeams(user);
      let message = "";
      if (data.length) {
        data.forEach((cur, index, array) => {
          if (index == array.length - 1) return (message = message + "" + cur.data().name + "");
          message = message + "" + cur.data().name + ",";
        });
        deleteModelMessage.value = `This user is a team owner of (${message}) teams. Do you still want to delete the user. You may delete the teams separately if needed.`;
      } else {
        deleteModelMessage.value = "Are you sure you want to delete this user?";
      }

      roleUser.value = user;
      showDeleteUserModal.value = true;
    };
    const checkTheUserHasTeams = async (user) => {
      let arrayQuery;
      let teamQuery;
      let arrayQuerySnapshot;
      let stringQuerySnapshot;
      let mergedQuerySnapshot;

      teamQuery = query(collection(db, "teams"), where("ownerId", "==", user.id)); // if admin | superadmin => can View all teams : can't View
      arrayQuery = query(collection(db, "teams"), where("ownerId", "array-contains", user.id));
      stringQuerySnapshot = await getDocs(teamQuery);

      if (arrayQuery != "") {
        stringQuerySnapshot = await getDocs(teamQuery);
        arrayQuerySnapshot = await getDocs(arrayQuery);
        mergedQuerySnapshot = [...stringQuerySnapshot.docs, ...arrayQuerySnapshot.docs];
        return mergedQuerySnapshot;
      }
      return [];
    };
    const cancelDeleteUser = () => {
      roleUser.value = null;
      showDeleteUserModal.value = false;
    };
    const updateRole = async () => {
      isProcessing.value = true;
      try {
        await updateDoc(doc(db, "profiles", roleUser.value.id), {
          role: selectedRole.value,
        });
        await fetchData();
        await closeRoleModal();
        toast.success("Role updated successfully!");
      } catch (e) {
        console.log("Error while updating role");
      }
      isProcessing.value = false;
    };
    const confirmDeleteUser = async () => {
      isProcessing.value = true;
      try {
        await deleteDoc(doc(db, "profiles", roleUser.value.id));
        await fetchData();
        roleUser.value = null;
        showDeleteUserModal.value = false;
        toast.success("User has been deleted successfully!");
      } catch (e) {
        console.log("Error while updating role");
      }
      isProcessing.value = false;
    };
    const handlePaginationButtonClick = (action) => {
      if (action === "pre" && selectedPaginationPage.value > 1) {
        selectedPaginationPage.value--;
      } else if (action === "next" && selectedPaginationPage.value < paginationPages.value) {
        selectedPaginationPage.value++;
      }
    };

    const onChangePagination = (page) => {
      selectedPaginationPage.value = page;
    };
    const formatDate = (timestamp) => {
      if (!timestamp || !timestamp.seconds) {
        return "N/A"; // Return a default value if timestamp is missing or invalid
      }
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    };

    return {
      profiles,
      totalNumberOfRows,
      paginationPages,
      selectedPaginationPage,
      showAssignRole,
      showDeleteUserModal,
      paginatedProfiles,
      showAssignRole,
      showDeleteUserModal,
      openRoleModal,
      openDeleteUserModal,
      handlePaginationButtonClick,
      onChangePagination,
      formatDate,
      roles,
      closeRoleModal,
      updateRole,
      selectedRole,
      ROLES,
      confirmDeleteUser,
      cancelDeleteUser,
      deleteModelMessage,
      searchTeam,
      searchQuery,
      // checkTheUserHasTeams,
    };
  },
};
</script>

<style scoped lang="scss">
$dark-blue: rgb(6 35 52);
$menu-link: #87c5ed;
$menu-link-hover: #fff;

.bg-side-bar {
  background-color: $dark-blue;
  transition: all ease-in-out 0.5s;

  a {
    color: $menu-link;
    text-shadow: 1px 1px #111;

    &:hover {
      color: $menu-link-hover;
    }
  }
}

.table-header {
  th {
    font-weight: 300;
    background-color: #ccdbe6;
    border-bottom: 1px solid #446a80;
  }
}
.cursor {
  cursor: pointer;
}
</style>
