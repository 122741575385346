<template>
  <div class="container py-5 privacy">
    <h1 class="mb-4">Privacy Policy</h1>
    <div class="row">
      <div class="col-md-12">
        <p>
          This Temporary Privacy Policy applies to the websites and services ("Services")
          operated by TEAM SHEET PRO, on which this policy is linked. TEAM SHEET PRO will
          update this Privacy Policy soon. This Policy sets out how TEAM SHEET PRO and
          certain of its partners may collect and use any personal information which you
          provide through the Services.
        </p>
        <h2>INFORMATION COLLECTED</h2>
        <p>
          TEAM SHEET PRO collects any information that you provide to the Services,
          including for example, when you register for an account and provide your email
          address, a password, and your name. Depending on how you use the Services, TEAM
          SHEET PRO may also collect:
        </p>
        <ul>
          <li>
            Contact information such as address and phone number of users and their
            associated members.
          </li>
          <li>
            Team member information, including where applicable information about related
            individuals.
          </li>
          <li>
            Team information, such as rosters, schedules, attendance, statistics, and
            scores.
          </li>
          <li>
            Activity information you upload, including photos, news, information and other
            content.
          </li>
          <li>Messages and feedback you send to TEAM SHEET PRO.</li>
          <li>Transactional information.</li>
        </ul>

        <h2>USE OF INFORMATION</h2>

        <p>Your information may be used by TEAM SHEET PRO to:</p>
        <ul>
          <li>Provide the Services.</li>
          <li>For any specific purpose for which it was provided or volunteered.</li>
          <li>To send you information.</li>
          <li>To Prevent fraud and defend legal rights.</li>
          <li>To comply with applicable law.</li>
        </ul>

        <h2>DISCLOSURE OF INFORMATION</h2>
        <p>TEAM SHEET PRO may share your information with:</p>

        <ul>
          <li>
            Service providers that perform certain business-related functions on its
            behalf.
          </li>
          <li>
            Soccer Player Recruiters: depending on how you use the Services your
            information may be available to other members of your team.
          </li>
          <li>As required by law in with Law Enforcement Agencies or Regulators.</li>
          <li>
            National Governing Bodies: TEAM SHEET PRO may share your information with the
            following National Governing Body of your sport, for instance to confirm your
            eligibility to participate in a sport.
          </li>
        </ul>

        <h2>AUTOMATICALLY COLLECTED</h2>
        <p>TEAM SHEET PRO may collect some information:</p>
        <p>For logged in users:</p>
        <ul>
          <li>device identifiers and usage data for analytics purposes,</li>
          <li>volunteer information such as name and email address.</li>
          <li>
            When administrators are logged in, may collect:
            <ul>
              <li>device identifiers and usage data for analytics purposes,</li>
              <li>
                administrator information such as name and email address data, and other
                relevant information when the administrator connects to TEAM SHEET PRO
              </li>
            </ul>
          </li>
        </ul>
        <h2>YOUR RIGHTS & CHOICES</h2>

        <ul>
          <li>
            You may unsubscribe from TEAM SHEET PRO's email newsletters or promotional
            emails by following the opt-out instructions contained in the email. You may
            not be able to unsubscribe from service-related or transactional emails.
          </li>
          <li>
            You may be able to access and correct certain information through the features
            provided by the Services or by contacting TEAM SHEET PRO.
          </li>
        </ul>

        <h2>CONTACT</h2>

        <p>
          Questions, comments and requests regarding this Privacy Policy should be
          addressed to TEAM SHEET PRO.
        </p>
      </div>
    </div>
  </div>
</template>
