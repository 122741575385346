
import { initializeApp } from "firebase/app";
import { getFirestore, collection, Firestore } from "firebase/firestore";
import {getStorage } from "firebase/storage";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB8CBlMd8g89O6cP5FEx9DRuNDLA_SfFIk",
  authDomain: "teamsheet1-67d8d.firebaseapp.com",
  databaseURL: "https://teamsheet1-67d8d-default-rtdb.firebaseio.com",
  projectId: "teamsheet1-67d8d",
  storageBucket: "teamsheet1-67d8d.appspot.com",
  messagingSenderId: "1012626793107",
  appId: "1:1012626793107:web:afe1b34c01e3358a8c6366",
  measurementId: "G-HPNH55QL55"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const Admin = initializeApp(firebaseConfig, "Secondary"); // Intialise "fbAdmin" as instance named "Secondary".
const authAdmin = getAuth(Admin); // Get the Auth service for the secondary app
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { db, auth, storage , authAdmin };
