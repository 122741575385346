<template>
  <div class="card px-0 container">
    <div class="card-body bg-accent-800 rounded">
      <form
        @submit.prevent="savePlayer"
        class="row strong-label"
      >
        <div class="col-md-4">
          <div class="card">
            <div class="card-body text-center">
              <!-- Static Image -->
              <img
                v-if="!croppedImageBlob && !player.photo && !cropperModalOpen"
                src="/images/team-player-profile.jpg"
                class="img-fluid"
              />

              <!-- Saved/Cropped Image -->
              <img
                v-if="player.photo && !croppedImageBlob"
                :src="player.photo"
                class="img-fluid"
              />

              <div class="position-relative d-flex justify-content-center align-items-center">
                <!-- Newly Cropped Image -->
                <img
                  v-if="croppedImageBlob"
                  :src="createObjectURL(croppedImageBlob)"
                  class="img-fluid"
                />
                <!-- Loading spinner -->
                <div
                  v-if="isProcessing"
                  class="loading-spinner"
                ></div>
              </div>

              <!-- Cropper Component -->
              <CropperComponent
                :modalOpen="cropperModalOpen"
                @cropperOpened="cropperModalOpen = true"
                @blobReady="handleCroppedImage"
                @imageBeingCropped="setProcessingState(true)"
                @imageCropped="setProcessingState(false)"
                @cropperClosed="cropperModalOpen = false"
                :stencil-size="{ width: 300, height: 300 }"
                :stencil-props="{
                  handlers: {},
                  movable: false,
                  resizable: false,
                  aspectRatio: 1,
                }"
                image-restriction="stencil"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card bg-transparent text-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>Team <span class="required">*</span></label>
                  <select
                    class="form-control"
                    v-model="player.teamId"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select a Team
                    </option>
                    <option
                      v-for="team in teams"
                      :key="team.id"
                      :value="team.id"
                    >
                      {{ team.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-4">
                  <label>First Name <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="player.firstName"
                    required
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-12 mb-4">
                  <label>Middle Name</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="player.middleName"
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-12 mb-4">
                  <label>Last Name <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="player.lastName"
                    required
                    @input="validateNameInput"
                  />
                  <span
                    v-if="nameError"
                    class="text-danger"
                    >Only text characters are allowed!</span
                  >
                </div>
                <div class="col-md-4 mb-4">
                  <label>Position<span class="required">*</span></label>
                  <MultiSelect
                    :options="positions"
                    :initialItems="player.position"
                    :handleSelcted="handleSelectedItems"
                  />

                  <!-- <select class="form-control" v-model="player.position" required>
                    <option disabled value="">Select a Position</option>
                    <option v-for="position in positions" :key="position.id" :value="position.code ? position.code : position.name">
                      {{ `${ position.code && position.code + " – "} ${position.name}` }}
                    </option>
                  </select> -->
                </div>

                <div class="col-md-4 mb-4">
                  <label>Shirt Number<span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="number"
                    v-model="player.shirtNumber"
                    required
                    @blur="checkShirtNumberDuplication"
                  />
                  <span
                    v-if="shirtNumberError"
                    class="text-danger"
                    >This shirt number is already taken!</span
                  >
                </div>

                <div
                  class="mb-4"
                  :class="[teamId ? 'col-md-12' : 'col-md-4']"
                >
                  <label>Enable Player</label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      value="Enable"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      :checked="player.display === 'Enable'"
                      v-model="player.display"
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      value="Disable"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      :checked="player.display === 'Disable'"
                      v-model="player.display"
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <label>Date of Birth</label>
                  <div
                    id="dob-calendar"
                    class="calendar"
                  >
                    <datepicker
                      placeholder="Enter Date"
                      :typeable="true"
                      class="dob-calendar"
                      v-model="player.dob"
                    >
                    </datepicker>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <label>Class Of <span class="required">*</span></label>
                  <div
                    id="class-calendar"
                    class="calendar"
                  >
                    <datepicker
                      placeholder="Enter Date"
                      :minimum-view="'year'"
                      :maximum-view="'year'"
                      :typeable="true"
                      class="class-of-calendar"
                      v-model="player.classOf"
                      required
                    >
                    </datepicker>
                  </div>
                </div>

                <div class="col-md-4 mb-4">
                  <label>Phone</label>
                  <input
                    class="form-control"
                    type="tel"
                    v-model="player.phone"
                  />
                </div>
                <div class="col-md-5 mb-4">
                  <label>Email</label> <span class="required">*</span>
                  <input
                    class="form-control"
                    type="email"
                    v-model="player.email"
                    required
                  />
                  <span
                    v-if="emailMsg"
                    class="text-danger"
                    >{{ emailMsg }}</span
                  >
                </div>
                <div class="col-md-3 mb-4">
                  <label>GPA</label>
                  <input
                    class="form-control"
                    type="number"
                    step="0.01"
                    @input="formatGPA"
                    v-model="player.gpa"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Player Bio</label>
                  <textarea
                    class="form-control"
                    v-model="player.bio"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 mb-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="isProcessing"
                >
                  Save
                </button>

                <button
                  class="btn btn-outline-light"
                  type="button"
                  @click="closeModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { auth, db, storage, authAdmin } from "../firebase";
import CropperComponent from "@/components/CropperComponent.vue";
import MultiSelect from "./MultiSelect.vue";
import { collection, addDoc, getDocs, updateDoc, doc, query, where, setDoc, serverTimestamp } from "firebase/firestore";
import { ref as fbRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useToast } from "vue-toastification";
import { watch } from "vue";
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { getUserRole } from "../utils/auth";
import ROLES from "../utils/roles.json";
import emailjs from "@emailjs/browser";
import { useRoute } from "vue-router";

export default {
  props: {
    initialPlayer: Object,
    teamDetail: [Array, Object],
    teamId: {
      type: [String, Number],
      default: null,
    },
  },

  components: {
    CropperComponent,
    MultiSelect,
  },

  setup(props, { emit }) {
    const isProcessing = ref(false);
    const toast = useToast();
    const cropperModalOpen = ref(false);
    const croppedImage = ref("");
    const croppedImageBlob = ref(null);
    const positions = ref([]);
    const nameError = ref(false);
    const teamShirtNumbers = ref([]);
    const shirtNumberError = ref(false);
    const emailMsg = ref("");
    const route = useRoute();

    const userRole = ref("");
    const canViewAll = ref(false);

    const player = ref({
      teamId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      position: "",
      dob: null,
      classOf: null,
      shirtNumber: null,
      phone: "",
      email: "",
      gpa: null,
      bio: "",
      photo: "",
      display: "Enable",
    });

    const teams = ref([]);

    watch(
      () => props.teamId,
      (newTeamId) => {
        player.value.teamId = newTeamId;
      }
    );

    watch(
      () => player.value.teamId,
      async (newTeamId) => {
        if (newTeamId) {
          teamShirtNumbers.value = await fetchTeamShirtNumbers(newTeamId);
        }
      }
    );

    const fetchTeamShirtNumbers = async (teamId) => {
      const playersCollection = collection(db, "players");
      const q = query(playersCollection, where("teamId", "==", teamId));
      const teamPlayersSnapshot = await getDocs(q);
      return teamPlayersSnapshot.docs.map((doc) => doc.data().shirtNumber);
    };

    const formatGPA = (event) => {
      let value = event.target.value;
      // Remove non-numeric characters except the decimal point
      value = value.replace(/[^\d.]/g, "");
      // Ensure only one decimal point
      if (value.indexOf(".") !== -1) {
        const parts = value.split(".");
        parts[1] = parts[1].slice(0, 2); // Keep only first two decimal places
        value = parts.join(".");
      }

      // Update the input value and the v-model binding
      player.value.gpa = value;
    };

    const createObjectURL = (blob) => {
      if (blob instanceof Blob) {
        return window.URL.createObjectURL(blob);
      }
      return "";
    };

    onMounted(async () => {
      if (props.teamId) {
        player.value.teamId = props.teamId;
      }

      if (props.initialPlayer) {
        let dob = null;
        if (props.initialPlayer.dob) {
          if (typeof props.initialPlayer.dob === "string") {
            dob = new Date(Date.parse(props.initialPlayer.dob.split(" ")[0]));
          } else if (props.initialPlayer.dob.toDate) {
            // Check if it's a firestore timestamp
            dob = props.initialPlayer.dob.toDate();
          }

          let classOf = null;
          if (props.initialPlayer.classOf) {
            if (typeof props.initialPlayer.classOf === "number") {
              classOf = new Date(props.initialPlayer.classOf, 0, 1);
            } else if (props.initialPlayer.classOf.toDate) {
              // Check if it's a firestore timestamp
              classOf = props.initialPlayer.classOf.toDate();
            } else {
              classOf = props.initialPlayer.classOf;
            }

            player.value = { ...props.initialPlayer, dob, classOf };
          }
        } else {
          player.value = { ...props.initialPlayer };
        }
      }
      try {
        teams.value = await fetchTeams();
        teams.value = teams.value.length ? teams.value : userRole.value == "player" ? getPlayerTeamDetail() : [];
        // console.log("Fetched teams:", teams.value);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }

      try {
        positions.value = await fetchPositions();
        positions.value.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
        positions.value.map((position) => {
          position.name = position.name = position.code ? position.code + " – " + position.name : position.name;
          position.id = position.code ? position.code : position.name;
        });
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    });

    const getPlayerTeamDetail = () => {
      return [{ ...props.teamDetail, id: route.params.teamId }];
    };
    const validateNameInput = (event) => {
      const regex = /^[a-zA-Z\s]*$/; // This regex allows only letters and spaces
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-letter characters
        nameError.value = true; // Set the error flag
        setTimeout(() => {
          nameError.value = false; // Reset the error flag after a delay (optional)
        }, 2000);
      }
    };
    const triggerImageSelection = () => {
      cropperModalOpen.value = true;
    };

    function setProcessingState(processing) {
      isProcessing.value = processing;
    }

    const fetchPositions = async () => {
      const positionsCollection = collection(db, "positions");
      const positionsSnapshot = await getDocs(positionsCollection);
      return positionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    };

    const fetchTeams = async () => {
      try {
        // Only fetch teams that belong to the currently logged-in user
        const teamsCollection = collection(db, "teams");
        let mergedQuerySnapshot;
        let stringQuerySnapshot;
        let arrayQuerySnapshot;
        // check view all peremission
        userRole.value = await getUserRole(auth.currentUser);
        canViewAll.value = ROLES.TEAM.VIEW.includes(userRole.value?.toLowerCase());
        const q = query(teamsCollection, !canViewAll.value && where("ownerId", "==", auth.currentUser.uid));
        const arrayQuery = !canViewAll.value ? query(teamsCollection, where("ownerId", "array-contains", auth.currentUser.uid)) : "";
        if (arrayQuery != "") {
          stringQuerySnapshot = await getDocs(q);
          arrayQuerySnapshot = await getDocs(arrayQuery);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs, ...arrayQuerySnapshot.docs];
        } else {
          stringQuerySnapshot = await getDocs(q);
          mergedQuerySnapshot = [...stringQuerySnapshot.docs];
        }
        const teamsSnapshot = await getDocs(q);
        // console.log("Raw teams fetched:", teamsSnapshot.docs);
        return mergedQuerySnapshot.map((doc) => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Failed to fetch teams:", error);
        return [];
      }
    };

    const closeModal = () => emit("closeModal");

    const handleCroppedImage = async (blob) => {
      croppedImageBlob.value = blob;
    };

    const updatePlayer = async () => {
      if (!props.initialPlayer || !props.initialPlayer.id) {
        toast.error("Cannot update, player information is missing!");
        return;
      }

      if (croppedImageBlob.value) {
        try {
          const uploadedImageUrl = await uploadImageToFirebase(croppedImageBlob.value, player.value);
          player.value.photo = uploadedImageUrl; // Update player object with the new image URL
          croppedImageBlob.value = null; // Reset it after uploading
        } catch (error) {
          toast.error("Failed to upload updated image.");
          return;
        }
      }

      // Validate shirtNumber
      if (isNaN(player.value.shirtNumber) || player.value.shirtNumber > 999) {
        toast.error("Shirt Number can only be a number up to 999!");
        return;
      }
      const isShirtNumberDuplicate = await checkShirtNumberDuplication(player.value.shirtNumber, player.value.teamId);
      if (isShirtNumberDuplicate) {
        toast.error("Another player in this team already has this shirt number!");
        return;
      }
      // Validate phone number (only numbers and symbols)
      const phoneRegex = /^[0-9\s\+\-\(\)]*$/;
      if (!phoneRegex.test(player.value.phone)) {
        toast.error("Phone can only contain numbers and symbols!");
        return;
      }

      // Validate GPA
      if (isNaN(player.value.gpa) || player.value.gpa > 10) {
        toast.error("GPA can only be a number and should not be more than 10!");
        return;
      }

      const currentPlayerDoc = doc(db, "players", props.initialPlayer.id);
      await updateDoc(currentPlayerDoc, player.value);

      toast.success("Player updated successfully!");

      // Reset the form
      player.value = {
        teamId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        position: "",
        dob: null,
        classOf: null,
        shirtNumber: null,
        phone: "",
        email: "",
        gpa: null,
        bio: "",
        photo: "",
        display: "",
      };

      emit("playerSaved");
    };

    const checkShirtNumberDuplication = async () => {
      if (!player.value.teamId) {
        toast.error("Please select a team first.");
        return;
      }

      const playersCollection = collection(db, "players");
      const q = query(playersCollection, where("teamId", "==", player.value.teamId), where("shirtNumber", "==", player.value.shirtNumber));
      const duplicateSnapshot = await getDocs(q);

      // If a duplicate is found and the found player isn't the one we're currently editing
      if (duplicateSnapshot.size > 0 && (!props.initialPlayer || duplicateSnapshot.docs[0].id !== props.initialPlayer.id)) {
        shirtNumberError.value = true;
      } else {
        shirtNumberError.value = false;
      }
    };

    const handleSelectedItems = (selectedItems) => {
      player.value.position = selectedItems.map((item) => item.id);
    };

    const savePlayer = async () => {
      isProcessing.value = false;

      if (!player.value.teamId) {
        toast.error("Please select a team.");
        return;
      }

      if (shirtNumberError.value) {
        toast.error("Failed saving a player. Another player has the shirt number already.");
        return;
      }

      if (player.value.id) {
        await updatePlayer();
      } else {
        if (croppedImageBlob.value) {
          try {
            const uploadedImageUrl = await uploadImageToFirebase(croppedImageBlob.value, player.value);
            player.value.photo = uploadedImageUrl; // Update player object with the new image URL
            croppedImageBlob.value = null; // Reset it after uploading
          } catch (error) {
            toast.error("Failed to upload image.");
            return;
          }
        }

        await addPlayer();
      }
    };

    const addPlayer = async () => {
      player.value.dob = player.value.dob instanceof Date ? player.value.dob.toISOString().split("T")[0] : player.value.dob;

      player.value.classOf = player.value.classOf instanceof Date ? player.value.classOf.toISOString().split("T")[0] : player.value.classOf;

      const nameRegex = /^[a-zA-Z\s]*$/;
      if (!nameRegex.test(player.value.firstName) || !nameRegex.test(player.value.middleName) || !nameRegex.test(player.value.lastName)) {
        toast.error("Names can only contain text characters!");
        return;
      }

      // Validate shirtNumber
      if (isNaN(player.value.shirtNumber) || player.value.shirtNumber > 999) {
        toast.error("Shirt Number can only be a number up to 999!");
        return;
      }

      // Validate phone number (only numbers and symbols)
      const phoneRegex = /^[0-9\s\+\-\(\)]*$/;
      if (!phoneRegex.test(player.value.phone)) {
        toast.error("Phone can only contain numbers and symbols!");
        return;
      }

      // Validate GPA
      if (isNaN(player.value.gpa) || player.value.gpa > 10) {
        toast.error("GPA can only be a number and should not be more than 10!");
        return;
      }

      if (!player.value.teamId) {
        toast.error("Please select a team.");
        return;
      }
      if (player.value.ageGroup && player.value.ageGroup instanceof Date) {
        player.value.ageGroup = player.value.ageGroup.getFullYear();
      }
      if (player.value.classOf && player.value.classOf instanceof Date) {
        player.value.classOf = player.value.classOf.getFullYear();
      }
      const playersCollection = collection(db, "players");

      if (props.initialPlayer) {
        // Update existing player
        const currentPlayerDoc = doc(db, "players", props.initialPlayer.id);
        await updateDoc(currentPlayerDoc, player.value);
        toast.success("Player updated successfully!");
      } else {
        try {
          //check if email is already registered player db and added with this team
          const q = query(playersCollection, where("email", "==", player.value.email.toLowerCase()), where("teamId", "==", player.value.teamId));
          const playersSnapshot = await getDocs(q);
          if (playersSnapshot.docs.length > 0) {
            throw "Player with this email already added to the team.";
          }

          //check if email is already registered player db
          const queryAlreadyExistEmail = query(playersCollection, where("email", "==", player.value.email.toLowerCase()));
          const alreadyPlayersSnapshot = await getDocs(queryAlreadyExistEmail);
          if (alreadyPlayersSnapshot.docs.length) {
            var templateParams = {
              email: player.value.email,
              subject: "Player Added",
              userUrl: "https://teamsheetpro.com",
              comment: `The message confirms that ${player.value.firstName} ${player.value.lastName} the player has successfully added with the team(${teams.value.filter((cur) => cur.id == player.value.teamId)[0]?.name}).`,
            };
            //send the information email for the current register user
            await emailjs.send("service_SheetProX", "template_8z7lfek", templateParams, "S6E9Jwpf9inCzK0X5");
          }
          // Add new player
          player.value.email = player.value.email.toLowerCase();
          const docRef = await addDoc(playersCollection, {
            ...player.value,
            addedBy: auth.currentUser.uid,
          });

          // If a new image was cropped and is ready to be uploaded
          if (croppedImageBlob.value) {
            try {
              const uploadedImageUrl = await uploadImageToFirebase(croppedImageBlob.value, player.value);
              player.value.photo = uploadedImageUrl; // Update player object with the new image URL
              croppedImageBlob.value = null; // Reset it after uploading
            } catch (error) {
              toast.error("Failed to upload image.");
              return;
            }
          }
          if (player.value.email) {
            // check if email is already registered
            const profiles = collection(db, "profiles");
            const q = query(profiles, where("email", "==", player.value.email.toLowerCase()));
            const playerSnapshot = await getDocs(q);

            if (playerSnapshot.docs.length <= 0) {
              // Create the user account without signing them in
              const password = Math.random().toString(36).slice(-8); //// generate random password
              try {
                const { user } = await createUserWithEmailAndPassword(
                  authAdmin,
                  player.value.email,
                  password // generate random password
                );
                //console.log(user,'user.sendEmailVerification()')
                await sendEmailVerification(user);

                var templateParams = {
                  email: player.value.email,
                  password: password,
                  subject: "Player Registration",
                  userUrl: "https://teamsheetpro.com",
                  comment: `The message confirms that ${player.value.firstName} ${player.value.lastName} the player has successfully registered with the team(${teams.value.filter((cur) => cur.id == player.value.teamId)[0]?.name}).`,
                };
                //send the information email for the current register user
                await emailjs.send("service_SheetProX", "template_requ01", templateParams, "S6E9Jwpf9inCzK0X5");

                if (user) {
                  // Create initial profile in Firestore for this user
                  await setDoc(doc(db, "profiles", user.uid), {
                    firstName: player.value.firstName || "",
                    middleName: player.value.middleName || "",
                    lastName: player.value.lastName || "",
                    title: "",
                    email: user.email,
                    phone: player.value.phone || "",
                    biography: "",
                    role: "player",
                    createdAt: serverTimestamp(),
                  });
                }
              } catch (error) {
                if (error.code === "auth/email-already-in-use") {
                  emailMsg.value = "This email is already registered. Please use a different email address.";
                  return;
                } else if (error.code === "Player with this email already added to the team.") {
                  emailMsg.value = "Player with this email already added to the team.";
                  return;
                } else {
                  console.log("Error creating user:", error);
                  emailMsg.value = "Invalid email.";
                  return;
                }
              }
            }
          }

          toast.success("Player added successfully!");
        } catch (error) {
          if (error === "Player with this email already added to the team.") {
            emailMsg.value = "Player with this email already added to the team.";
            return;
          }
        }
      }

      player.value = {
        teamId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        position: "",
        dob: null,
        classOf: null,
        shirtNumber: null,
        phone: "",
        email: "",
        gpa: null,
        bio: "",
        photo: "",
      };

      emit("playerSaved");
      emit("playerAdded");
    };

    function setProcessingState(processing) {
      isProcessing.value = processing;
    }

    const generateFilename = (player) => {
      const date = new Date().toISOString();
      const playerName = `${player.firstName}_${player.lastName}`.toLowerCase().replace(/\s+/g, "_");
      return `${playerName}_${date}.jpg`;
    };

    const uploadImageToFirebase = async (blob, player) => {
      const filename = generateFilename(player);
      const storageRef = fbRef(storage, `player_photos/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, blob);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.log(error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    };

    return {
      player,
      teams,
      addPlayer,
      updatePlayer,
      closeModal,
      handleCroppedImage,
      triggerImageSelection,
      cropperModalOpen,
      isProcessing,
      emailMsg,
      setProcessingState,
      croppedImage,
      positions,
      validateNameInput,
      nameError,
      createObjectURL,
      croppedImageBlob,
      savePlayer,
      shirtNumberError,
      canViewAll,
      handleSelectedItems,
      formatGPA,
      getPlayerTeamDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.loading-spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  transform: translate(50%, 50%);
}
::v-deep(.vuejs3-datepicker__typeablecalendar) {
  right: unset;
  left: 10px;
  top: 10px;
}
::v-deep(.vuejs3-datepicker__inputvalue) {
  padding: 12px 10px 13px 35px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
