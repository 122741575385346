<template>
  <div class="container py-4 league">
    <LeaguesList />
  </div>
</template>
<script>
import LeaguesList from "@/components/LeaguesList.vue";
export default {
  name: "Leagues",
  components: {
    LeaguesList,
  },
};
</script>
