<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <MenuSuperAdmin
        :userCount="userCount"
        :leagueCount="leagueCount"
        :clubCount="clubCount"
        :teamCount="teamCount"
        :playerCount="playerCount"
        :memberCount="memberCount"
        :eventCount="eventCount"
      />

      <router-view />
    </div>
  </div>
</template>

<script>
import UsersList from "@/components/UsersList.vue";
import MenuSuperAdmin from "@/layouts/MenuSuperAdmin.vue";
import { ref } from "vue"; // Import ref from Vue
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "DashSuperAdminPage",
  components: {
    MenuSuperAdmin,
  },
  setup() {
    // Define refs for record counts
    const userCount = ref(0);
    const leagueCount = ref(0);
    const clubCount = ref(0);
    const teamCount = ref(0);
    const playerCount = ref(0);
    const memberCount = ref(0);
    const eventCount = ref(0);

    // Fetch record counts from Firestore
    const fetchRecordCounts = async () => {
      const usersRef = collection(db, "profiles");
      const leaguesRef = collection(db, "leagues");
      const clubsRef = collection(db, "clubs");
      const teamsRef = collection(db, "teams");
      const playersRef = collection(db, "players");
      const membersRef = collection(db, "members");
      const eventsRef = collection(db, "teamEvents");

      const usersSnapshot = await getDocs(usersRef);
      const leaguesSnapshot = await getDocs(leaguesRef);
      const clubsSnapshot = await getDocs(clubsRef);
      const teamsSnapshot = await getDocs(teamsRef);
      const playersSnapshot = await getDocs(playersRef);
      const membersSnapshot = await getDocs(membersRef);
      const eventsSnapshot = await getDocs(eventsRef);

      userCount.value = usersSnapshot.size;
      leagueCount.value = leaguesSnapshot.size;
      clubCount.value = clubsSnapshot.size;
      teamCount.value = teamsSnapshot.size;
      playerCount.value = playersSnapshot.size;
      memberCount.value = membersSnapshot.size;
      eventCount.value = eventsSnapshot.size;
    };

    // Fetch record counts when the component is mounted
    fetchRecordCounts();

    // Return the record counts as reactive variables
    return { userCount, leagueCount, clubCount, teamCount, memberCount, playerCount, eventCount };
  },
};
</script>
