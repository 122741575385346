<template>
  <div class="d-none d-sm-flex col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-side-bar">
    <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
      <a
        href="/"
        class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span class="fs-6 d-none d-sm-inline fw-bold">Menu</span>
      </a>
      <ul
        class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
        id="menu"
      >
        <li>
          <router-link
            to="/superadmin-dashboard/users"
            class="nav-link px-0 align-middle"
            active-class="active"
          >
            <i class="fs-6 bi-table"></i>
            <span class="ms-1 d-none d-sm-inline">Users ({{ userCount || "x" }})</span>
          </router-link>
        </li>
        <li>
          <a
            href="#"
            class="nav-link px-0 align-middle"
          >
            <i class="fs-6 bi-table"></i>
            <span class="ms-1 d-none d-sm-inline">Requests ({{ playerCount || "x" }})</span>
          </a>
        </li>

        <li>
          <a
            href="#submenu3"
            data-bs-toggle="collapse"
            class="nav-link px-0 align-middle"
          >
            <i class="fs-6 bi-table"></i>
            <span class="ms-1 d-none d-sm-inline">Leagues ({{ leagueCount || "x" }})</span>
          </a>
        </li>
        <li class="w-100">
          <a
            href="#"
            class="nav-link px-0"
          >
            <span class="d-none d-sm-inline">Clubs ({{ clubCount || "x" }})</span>
          </a>
        </li>
        <li class="w-100">
          <a
            href="#"
            class="nav-link px-0"
          >
            <span class="d-none d-sm-inline">Teams ({{ teamCount || "x" }})</span>
          </a>
        </li>

        <li>
          <a
            href="#"
            class="nav-link px-0"
          >
            <span class="d-none d-sm-inline">Staff Members ({{ memberCount || "x" }})</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="nav-link px-0"
          >
            <span class="d-none d-sm-inline">Events ({{ eventCount || "x" }})</span></a
          >
        </li>

        <li>
          <router-link
            to="/superadmin-dashboard/emailjs"
            class="nav-link px-0 align-middle"
            active-class="active"
          >
            <i class="fs-4 bi-table"></i>
            <span class="ms-1 d-none d-sm-inline">EmailJS</span>
          </router-link>
        </li>
      </ul>
      <hr />
    </div>
  </div>
</template>

<script>
import { ref } from "vue"; // Import ref from Vue

export default {
  props: {
    userCount: Number, // Receive user count as a prop
    leagueCount: Number, // Receive league count as a prop
    clubCount: Number,
    teamCount: Number,
    memberCount: Number,
    eventCount: Number,
    playerCount: Number,
  },
};
</script>

<style scoped lang="scss">
$dark-blue: #0d325f;
$menu-link: #87c5ed;
$menu-link-hover: #fff;

.bg-side-bar {
  background-color: $dark-blue;
  transition: all ease-in-out 0.5s;

  a {
    color: $menu-link;
    text-shadow: 1px 1px #111;

    &:hover {
      color: $menu-link-hover;
    }
    &.active {
      background-color: transparent;
    }
  }
}
</style>
