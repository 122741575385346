<template>

  <div class="d-flex justify-content-between pb-2">
    <div class=" mb-2">
      <h3>Leagues</h3>
    </div>
    <div class="text-end pe-0" v-if="canCreate && !loading">
      <button @click="showAddLeagueModal = true" class="btn btn-primary">
        + Add League
      </button>
    </div>
    </div>
    <div class="row">
    <div class="px-0">
      <div class="card border-0 bg-transparent d-none d-sm-block">
        <div class="card-body py-1">
          <div class="row">
            <div class="col-md-1 col-sm-2"><span class="mini-label">Logo</span></div>
            <div class="col"><span class="mini-label">Name</span></div>
            <div class="col-md-2 text-end"><span class="mini-label">Clubs</span></div>
            <div class="col-md-2 text-end"><span class="mini-label">Action</span></div>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div v-for="n in 6" v-if="loading && !leagues.length"> 
        <PreLoaderComponent heightPx="95px"/>
      </div>
      <div v-if="leagues.length"
        class="card mb-2 card-list-decoration no-deco"
        v-for="(league, index) in leagues"
        :key="index"
      >
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-1 col-sm-2 align-items-center justify-content-start d-flex"
            >
              <img
                :src="league.logo || '/images/league-logo-default-thumb.jpg'"
                alt="League Logo"
                width="60"
                height="60"
              />
            </div>

            <div class="col">
              <div class="d-flex flex-column">
                <h4 class="mb-0 pb-0">{{ league.shortName }}</h4>
                <small class="text-secondary mt-0 pt-0"> {{ league.name }}</small>
              </div>
            </div>
            <div class="col-md-2 align-items-center justify-content-end d-flex">
              <small>Total Clubs: {{ league.clubCount }}</small>
            </div>

            <div v-if="canUpdate || canDelete" class="col-md-2 align-items-center justify-content-end d-flex">
              <a v-if="canUpdate" href="#" class="px-2" @click.prevent="openEditModal(league)">Edit</a>
              <span class="text-secondary">|</span>
              <a v-if="canDelete" href="#" class="px-2" @click.prevent="showDeleteConfirmation(league)"
                >Delete</a
              >
            </div>
            <div v-else class="col-md-2 align-items-center justify-content-end d-flex">
              <span class="text-secondary">No Actions</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!leagues.length && !loading">
      <div class="alert alert-info">
        <p>There are no leagues, add a league to start.</p>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal-mask modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Deleting the League</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="showModal = false"
          ></button>
        </div>
        <div class="modal-body">
          <p v-if="selectedLeague && selectedLeague.clubCount > 0">
            You cannot delete this league because it has associated clubs. Delete the
            clubs first before you can delete the league.
          </p>
          <p v-else class="text-center">Are you sure you want to delete this league?</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-secondary-outline" @click="showModal = false">
            No, Cancel
          </button>
          <button
            class="btn btn-sm btn-danger"
            v-if="selectedLeague && selectedLeague.clubCount === 0"
            @click="deleteLeague"
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalComponent v-model="showEditLeagueModal" header="Edit League">
    <LeagueFormComponent
      :initialLeague="editingLeague"
      @leagueSaved="onLeagueSaved"
      @closeModal="handleCloseModal"
    />
  </ModalComponent>

  <ModalComponent v-model="showAddLeagueModal" header="Add League">
    <LeagueFormComponent
      @leagueSaved="onLeagueAdded"
      @closeModal="showAddLeagueModal = false"
    />
  </ModalComponent>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import PreLoaderComponent from "@/components/PreLoaderComponent.vue";
import LeagueFormComponent from "./LeagueFormComponent.vue";
import { ref, onMounted } from "vue";
import { db , auth } from "../firebase";
import { collection, getDocs, deleteDoc, query, where, doc, getDoc } from "firebase/firestore";
import { useToast } from "vue-toastification";
import {getUserRole} from "../utils/auth"
import { onAuthStateChanged } from "firebase/auth";
import ROLES from "../utils/roles.json"

export default {
  components: {
    ModalComponent,
    LeagueFormComponent,
    PreLoaderComponent
  },

  setup() {
    const leagues = ref([]);
    const showAddLeagueModal = ref(false);
    const showEditLeagueModal = ref(false);
    const editingLeague = ref(null);
    const userRole = ref("")
    const canCreate = ref(false)
    const canUpdate = ref(false)
    const canDelete = ref(false)
    const loading = ref(true)

    onAuthStateChanged(auth, async (user) => {
      userRole.value = await getUserRole(user);
      canCreate.value = ROLES.LEAGUE.CREATE.includes(userRole.value?.toLowerCase())
      canUpdate.value = ROLES.LEAGUE.EDIT.includes(userRole.value?.toLowerCase())
      canDelete.value = ROLES.LEAGUE.DELETE.includes(userRole.value?.toLowerCase())
    });
    

    const fetchNumberOfClubs = async (leagueId) => {
      const clubsCollection = collection(db, "clubs");
      const clubsQuery = query(clubsCollection, where("leagueId", "==", leagueId));
      const clubsSnapshot = await getDocs(clubsQuery);
      return clubsSnapshot.docs.length;
    };

    const fetchLeagues = async () => {
      const leagueCollection = collection(db, "leagues");
      const leagueSnapshot = await getDocs(leagueCollection);

      // Sort the leagues alphabetically by shortName
      const sortedLeagues = leagueSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => a.shortName.localeCompare(b.shortName));

      for (let league of sortedLeagues) {
        league.clubCount = await fetchNumberOfClubs(league.id);
      }

      leagues.value = sortedLeagues;
    };
    const handleCloseModal = () => {
      showEditLeagueModal.value = false;
    };
    onMounted(async () => {
      const leagueCollection = collection(db, "leagues");
      const leagueSnapshot = await getDocs(leagueCollection);

      // Sort the leagues alphabetically by shortName
      const sortedLeagues = leagueSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => a.shortName.localeCompare(b.shortName));

      for (let league of sortedLeagues) {
        league.clubCount = await fetchNumberOfClubs(league.id);
      }
      leagues.value = sortedLeagues;
      loading.value = false
    });

    const openEditModal = (selectedLeague) => {
      editingLeague.value = selectedLeague;
      showEditLeagueModal.value = true;
    };

    const onLeagueAdded = () => {
      showAddLeagueModal.value = false;
      fetchLeagues();
    };

    const onLeagueSaved = () => {
      showEditLeagueModal.value = false;
      fetchLeagues();
    };

    const showModal = ref(false);
    const selectedLeague = ref(null);

    const showDeleteConfirmation = (league) => {
      selectedLeague.value = league;
      showModal.value = true;
    };
    const deleteLeague = async () => {
      if (selectedLeague.value) {
        try {
          const leagueRef = doc(db, "leagues", selectedLeague.value.id);
          await deleteDoc(leagueRef); // Delete league from Firestore

          const index = leagues.value.findIndex(
            (league) => league.id === selectedLeague.value.id
          );
          if (index !== -1) {
            leagues.value.splice(index, 1); // Remove from local array
          }

          showModal.value = false; // Close the modal
          selectedLeague.value = null; // Reset selected league
          const toast = useToast();
          toast.success("League deleted successfully!");
        } catch (error) {
          console.error("Error deleting league:", error);
          const toast = useToast();
          toast.error("Error deleting league!");
        }
      }
    };

    return {
      leagues,
      showModal,
      openEditModal,
      selectedLeague,
      showDeleteConfirmation,
      deleteLeague,
      onLeagueAdded,
      onLeagueSaved,
      showAddLeagueModal,
      showEditLeagueModal,
      editingLeague,
      userRole,
      handleCloseModal,
      loading,
      canCreate,
      canUpdate,
      canDelete
    };
  },
};
</script>

<style lang="scss" scoped>
$gray-60: #777;
.no-deco {
  a {
    text-decoration: none;
  }
}
.card-list-decoration {
  transition: all ease-in-out 0.5s;
  &:hover {
    background-color: #f6fbff;
    box-shadow: 1px 1px 18px #b7c7cd;
  }
}
.mini-label {
  color: $gray-60;
  font-size: 0.8rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Arial, Helvetica, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #1c67ac;
}

.modal-body {
  margin: 20px 0;
}
</style>
