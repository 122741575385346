<template>
    <div class="container mt-5">
        <h3 class="mb-4">Change Role Request</h3>
        <div class="row">
            <div class="col-7">
                <form @submit.prevent="submitForm">
                    <!-- Full Name -->
                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="firstName" class="form-label">First Name {{ profile.firstName }}</label>
                                <input type="text" class="form-control" v-model="firstName" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="middleName" class="form-label">Middle Name</label>
                                <input type="text" class="form-control" v-model="middleName" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="lastName" class="form-label">Last Name</label>
                                <input type="text" class="form-control" v-model="lastName" :disabled="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <!-- Email Address -->
                            <div class="mb-3">
                                <label for="email" class="form-label">Email Address</label>
                                <input type="email" class="form-control" v-model="email" required :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Phone Number -->
                            <div class="mb-3">
                                <label for="phoneNumber" class="form-label">Phone Number</label>
                                <input type="tel" class="form-control" v-model="phoneNumber" :disabled="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <!-- Current Role -->
                            <div class="mb-3">
                                <label for="currentrole" class="form-label">Current Role</label>
                                <input type="text" class="form-control" v-model="currentrole" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Request Subject Dropdown -->
                            <div class="mb-3">
                                <label for="requestRole" class="form-label">Change my Role to:</label>
                                <select class="form-select" v-model="requestRole" required>
                                    <option value="" disabled>Select Your Desired Role</option>
                                    <option v-for="role in requestRoles" :key="role">{{ role }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col mb-3">
                            <label for="requestMessage" class="form-label">Request Reason</label>
                            <textarea class="form-control" placeholder="Explain briefly why you need this role change"
                                v-model="requestMessage">

                </textarea>
                        </div>
                    </div>
                    <!-- Submit Button -->
                    <button type="submit" class="btn btn-primary">
                        Submit Request
                        <div class="spinner-border spinner-border-sm ms-2" role="status" v-if="requestLoading"></div>
                    </button>
                </form>
            </div>
            <div class="col-5">
                <img class="w-100" src="../assets/images/right-logo.png">
            </div>
        </div>
    </div>
</template>
<script>
import emailjs from '@emailjs/browser';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { onMounted, ref, reactive } from 'vue';
import { useToast } from 'vue-toastification';
import { auth, db } from '../firebase';

export default {
    setup() {
        const profile = reactive({
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            currentRole: '',
            requestMessage: '',
        });

        const requestRole = ref('');
        const requestRoles = ['teamowner', 'admin', 'subscriber'];
        const requestLoading = ref(false);
        const emailJsConfig = ref('');

        const submitForm = () => {
            sendEmail();
        };

        onMounted(async () => {
            const user = auth.currentUser;
            if (user) {
                const profileDoc = await getDocs(query(collection(db, 'profiles'), where('uid', '==', user.uid)));
                if (profileDoc.size > 0) {
                    const profileData = profileDoc.docs[0].data();
                    profile.firstName = profileData.firstName || '';
                    profile.middleName = profileData.middleName || '';
                    profile.lastName = profileData.lastName || '';
                    profile.email = profileData.email || '';
                    profile.phoneNumber = profileData.phone || '';
                    profile.currentRole = profileData.role || '';
                }
            }

            const emailJsDoc = query(collection(db, 'emailJs'), where('id', '==', 'requestConfig'));
            const emailJsSnapshot = await getDocs(emailJsDoc);
            emailJsSnapshot.forEach((doc) => {
                emailJsConfig.value = doc.data();
            });
        });

        const sendEmail = () => {
            requestLoading.value = true;
            const templateParams = {
                from_firstname: profile.firstName,
                from_middlename: profile.middleName,
                from_lastname: profile.lastName,
                from_email: profile.email,
                from_phone: profile.phoneNumber,
                request_role: requestRole.value,
                request_message: profile.requestMessage,
            };

            emailjs
                .send(
                    emailJsConfig.value.serviceID,
                    emailJsConfig.value.templateID,
                    templateParams,
                    emailJsConfig.value.publicKey
                )
                .then(
                    (response) => {
                        profile.firstName = '';
                        profile.middleName = '';
                        profile.lastName = '';
                        profile.email = '';
                        profile.phoneNumber = '';
                        requestRole.value = '';
                        profile.requestMessage = '';
                        requestLoading.value = false;
                        const toast = useToast();
                        toast.success('Request sent successfully, we will get back to you soon.');
                    },
                    (err) => {
                        console.log('FAILED...', err);
                    }
                );
        };

        return {
            profile,
            requestRole,
            requestRoles,
            requestLoading,
            submitForm,
        };
    },
};
</script>

