<template>
  <div id="app">
    <cropper v-if="showCropper" ref="cropper" class="cropper" :src="image.src"
	:auto-zoom="true"
	:stencil-size="{
		width: 280,
		height: 280
	}"
  	image-restriction="stencil" />

    <div class="button-wrapper">
      <button class="btn btn-outline-primary me-2" type="button" @click="prepareUpload">
        <input type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
        Add image
      </button>
      <button
        v-if="showCropper && image.src"
        class="btn btn-outline-primary"
        type="button"
        @click="cropAndEmitImage"
      >
        Crop image
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  components: {
    Cropper,
  },

  data() {
    return {
      image: {
        src: "",
        type: "",
      },
      showCropper: false,
    };
  },

  methods: {
    prepareUpload() {
      this.showCropper = true;
      this.$refs.file.click();
      this.$emit("imageBeingCropped");
      this.$emit("cropperOpened"); // Notify parent that cropper is opened
    },
    cropAndEmitImage() {
      const result = this.$refs.cropper.getResult({
        width: 300,
        height: 300,
        stretchMode: "fill",
      });
      const blob = this.dataURItoBlob(result.canvas.toDataURL(this.image.type));
      this.$emit("blobReady", blob);
      this.$emit("imageCropped");
      this.showCropper = false;
      this.$emit("cropperClosed");
    },
    dataURItoBlob(dataURI) {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    },
    uploadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        this.showCropper = true;
        const blob = URL.createObjectURL(files[0]);
        this.image = {
          src: blob,
          type: files[0].type,
        };
        this.$emit("imageUploaded"); // Added to notify the parent that an image has been uploaded.
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.cropper {
  max-height: 500px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}
input {
  display: none;
}
</style>
