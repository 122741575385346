<template>
  <div class="container py-4 edit-club">
    <CropperComponent />
  </div>
</template>

<script>
import CropperComponent from "@/components/CropperComponent.vue";

export default {
  name: "CropperPage",
  components: {
    CropperComponent,
  },
};
</script>
