<template>
    <vue-recaptcha v-show="showRecaptcha" sitekey="6LeGSeIoAAAAAAb0d72XzZGWjDq0gXX79HLnQatj" size="normal" theme="light"
        hl="en" :loading-timeout="loadingTimeout" @verify="recaptchaVerified" @expire="recaptchaExpired"
        @fail="recaptchaFailed" @error="recaptchaError" ref="vueRecaptcha">
    </vue-recaptcha>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { useStore } from 'vuex';

export default {
    name: 'RecaptchaComponent',
    components: {
        vueRecaptcha
    },
    setup() {
        const store = useStore(); // Access the Vuex store

        const showRecaptcha = true;
        const loadingTimeout = 30000; // 30 seconds

        const recaptchaVerified = (response) => {
            store.dispatch('updateRecaptchaStatus', true); // Update reCAPTCHA status in the store
        }

        const recaptchaExpired = () => {
            this.$refs.vueRecaptcha.reset();
        }

        const recaptchaFailed = () => {
            //
        }

        const recaptchaError = (reason) => {
            //
        }

        const isRecaptchaVerified = store.getters.isRecaptchaVerified;

        return {
            showRecaptcha,
            loadingTimeout,
            isRecaptchaVerified,
            recaptchaVerified,
            recaptchaExpired,
            recaptchaFailed,
            recaptchaError,
        };
    }
};
</script>
