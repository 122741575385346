<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>
      The page you are looking for might have been removed or is temporarily unavailable.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent",
};
</script>
