<template>
  <div class="card">
    <div class="card-body bg-accent-800 rounded">
      <form class="row strong-label" @submit.prevent="addLeague">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body text-center">
              <!-- Static Image -->
              <img
                v-if="!league.logo && !cropperModalOpen && !croppedImage"
                src="images/league-logo-default.jpg"
                class="img-fluid"
              />

              <!-- Saved/Cropped Image -->
              <img
                v-if="league.logo && !cropperModalOpen && !croppedImage"
                :src="league.logo"
                class="img-fluid"
              />

              <!-- Newly Cropped Image -->
              <img
                v-if="croppedImage && !cropperModalOpen"
                :src="croppedImage"
                class="img-fluid"
              />

              <!-- Cropper Component -->
              <CropperComponent
                :modalOpen="cropperModalOpen"
                @cropperOpened="cropperModalOpen = true"
                @blobReady="handleCroppedImage"
                @imageUploaded="handleImageUpload"
                @imageBeingCropped="setProcessingState(true)"
                @imageCropped="setProcessingState(false)"
                @cropperClosed="cropperModalOpen = false"
                :stencil-size="{ width: 300, height: 300 }"
                :stencil-props="{
                  handlers: {},
                  movable: false,
                  resizable: false,
                  aspectRatio: 1,
                }"
                image-restriction="stencil"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card bg-transparent text-white">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>League Name<span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="league.name"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>League Short Name<span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="league.shortName"
                    required
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>About the League</label>
                  <textarea
                    class="form-control"
                    type="text"
                    v-model="league.description"
                  />
                </div>
              </div>
              <div class="col-md-12 mb-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="isImageBeingProcessed"
                >
                  Save
                </button>

                <button class="btn btn-outline-light" type="button" @click="closeModal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router"; // Ensure this line exists
import { auth, db, storage } from "../firebase";
import { collection, addDoc, getDocs, updateDoc, doc } from "firebase/firestore";
import { ref as fbRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CropperComponent from "./CropperComponent.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    CropperComponent,
  },
  props: ["initialLeague"],

  setup(props, { emit }) {
    const router = useRouter();
    const toast = useToast();

    const league = ref({
      name: "",
      shortName: "",
      description: "",
      logo: null,
    });

    onMounted(() => {
      if (props.initialLeague) {
        league.value = { ...props.initialLeague };
      }
    });

    const croppedImageBlob = ref(null);
    const cropperModalOpen = ref(false);
    const isImageBeingProcessed = ref(false);
    const croppedImage = ref(null);

    // Methods
    const closeModal = () => emit("closeModal");

    const setProcessingState = (state) => {
      isImageBeingProcessed.value = state;
    };

    const handleImageUpload = () => {
      isImageBeingProcessed.value = true;
    };

    const handleCroppedImage = async (blob) => {
  try {
    const base64String = await blobToBase64(blob);
    league.value.logo = base64String;
  } catch (error) {
    console.error("Failed to convert image", error);
  }
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
    //ADD LEAGUE

    const addLeague = async () => {
      if (!props.initialLeague) {
        // Check only if it's a new league
        const leagues = await fetchLeagues();

        const duplicateNameLeague = leagues.find((l) => l.name === league.value.name);
        const duplicateShortNameLeague = leagues.find(
          (l) => l.shortName === league.value.shortName
        );

        if (duplicateNameLeague) {
          toast.error("This league name already exists.");
          return;
        }

        if (duplicateShortNameLeague) {
          toast.error("This league short name already exists.");
          return;
        }
      }

      if (croppedImageBlob.value) {
        league.value.logo = await uploadImage(croppedImageBlob.value);
      }

      saveLeague();
    };

    const uploadImage = async (blob) => {
      const storageRef = fbRef(
        storage,
        `league_logos/${auth.currentUser.uid}_${new Date().getTime()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, blob);

      return new Promise((resolve, reject) => {
        uploadTask.on("state_changed", null, reject, async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        });
      });
    };

    const fetchLeagues = async () => {
      const leaguesCollection = collection(db, "leagues");
      const leaguesSnapshot = await getDocs(leaguesCollection);
      return leaguesSnapshot.docs.map((doc) => doc.data());
    };

    const saveLeague = async () => {
      if (croppedImageBlob.value) {
        league.value.logo = await uploadImage(croppedImageBlob.value);
      }

      const leaguesCollection = collection(db, "leagues");

      if (props.initialLeague) {
        // Updating existing league
        const leagueDoc = doc(db, "leagues", props.initialLeague.id);
        await updateDoc(leagueDoc, league.value);
        toast.success("League updated successfully!");
      } else {
        // Adding a new league
        await addDoc(leaguesCollection, {
          name: league.value.name,
          shortName: league.value.shortName,
          description: league.value.description,
          logo: league.value.logo,
          addedBy: auth.currentUser.uid,
        });
        toast.success("League added successfully!");
        league.value = { name: "", shortName: "", description: "", logo: null };
      }
      emit("leagueSaved");
    };

    return {
      league,
      addLeague,
      handleCroppedImage,
      handleImageUpload,
      cropperModalOpen,
      closeModal,
      isImageBeingProcessed,
      setProcessingState,
      croppedImage,
    };
  },
};
</script>
