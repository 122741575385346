<template>
  <div v-bind="$attrs">
    <div
      v-if="isLoading"
      class="loading-spinner"
    ></div>
    <div
      v-else
      class="container px-0"
    >
      <div class="row pb-4">
        <div class="col-md-12 d-flex justify-content-between">
          <h3>Staff Members</h3>
          <button
            v-if="canCreate || userId == teamOwnerId"
            @click="handleAddMember"
            class="btn btn-primary"
          >
            + Add Member
          </button>
        </div>
      </div>
      <div
        v-if="members && members.length > 0"
        class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-3 g-2"
      >
        <div
          class="col col-sm-6 col-md-4 card-group"
          v-for="member in sortedMembers"
          :key="member.id"
          aria-current="true"
        >
          <div
            class="card mb-3 p-0 item-card"
            style=""
          >
            <div class="row g-0">
              <div class="col-md-4 position-relative text-center">
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  :alt="member.lastName"
                  :style="{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${member.photo ? member.photo : 'images/team-player-profile.jpg'})`,
                  }"
                  class="img-fluid w-100 player-image"
                />
              </div>
              <div class="col-md-8">
                <div class="card-body position-relative ms-2 mt-1">
                  <h5 class="card-title smaller-title">
                    {{ member.firstName }} {{ member.middleName }}
                    {{ member.lastName }}
                  </h5>
                  <p class="p-2 badge rounded-pill bg-accent">
                    {{ getMemberTitle(member.titleId) }}
                  </p>
                  <br />
                  <div v-if="currentUser?.value && teamOwnerId?.value">Current User ID: {{ currentUser.value?.id }} - Team Owner ID: {{ teamOwnerId.value }}</div>
                </div>
              </div>
            </div>
            <div
              class="card-footer d-flex"
              v-if="showFooter || isLoggedUser"
            >
              <div class="col text-end">
                <small class="text-muted">
                  <router-link
                    v-if="showFooter || isLoggedUser"
                    :to="`/member-detail/${member.id}`"
                    class="btn btn-sm btn-link p-0"
                  >
                    Member Detail
                  </router-link>
                  <button
                    v-if="showFooter || isLoggedUser"
                    class="btn btn-link btn-sm ms-1"
                    @click.prevent="editMember(member)"
                  >
                    Edit
                  </button>
                  <button
                    v-if="showFooter"
                    @click="deleteMember(member.id)"
                    class="btn btn-link btn-sm ms-1"
                  >
                    Delete
                  </button>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info">
          <p>There are no staff members in this squad. Add a a staff member to start</p>
        </div>
      </div>
    </div>
    <!-- Add Member Modal -->
    <ModalComponent
      v-model="showAddMemberModal"
      header="Add Staff Member to Team"
    >
      <MemberFormComponent
        :teamId="teamId"
        @memberAdded="onMemberAdded"
        @closeModal="handleCloseAddMemberModal"
      />
    </ModalComponent>

    <!-- Edit Member Modal -->
    <ModalComponent
      v-model="showEditMemberModal"
      header="Edit Staff Member"
    >
      <MemberFormComponent
        :initialMember="currentMember"
        @MemberSaved="onMemberSaved"
        @closeModal="handleCloseEditMemberModal"
      />
    </ModalComponent>

    <!-- Confirmation Modal for Deleting  Member -->
    <ModalComponent
      v-model="showDeleteMemberModal"
      header="Delete Member"
    >
      <div>
        Are you sure you want to delete this staff member?
        <div class="mt-3 d-flex justify-content-end">
          <button
            @click="confirmDeleteMember"
            class="btn btn-danger me-2"
          >
            Yes
          </button>
          <button
            @click="cancelDelete"
            class="btn btn-secondary"
          >
            No
          </button>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { ref, onMounted, onUnmounted, computed } from "vue";
import { db } from "../firebase";
import { useRoute, useRouter } from "vue-router";
import { collection, getDocs, query, where, doc, getDoc, deleteDoc } from "firebase/firestore";
import { storage } from "../firebase";
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";

import MemberFormComponent from "./MemberFormComponent.vue";
import ModalComponent from "./ModalComponent.vue";
import { useToast } from "vue-toastification";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { watch } from "vue";
import ROLES from "../utils/roles.json";
import { getUserRole } from "../utils/auth";

export default {
  components: {
    MemberFormComponent,
    ModalComponent,
  },
  props: {},

  data() {
    return {
      activeTab: "squad",
    };
  },

  setup(props) {
    const currentUser = ref(null);
    const teamOwnerId = ref(null);
    const currentDeleteType = ref(null);
    const currentMember = ref(null);
    const isLoading = ref(true);
    const activeTab = ref("squad");
    const userId = auth.currentUser.uid;

    const team = ref({
      clubDetails: {
        logo: "",
        shortName: "",
      },
    });
    const titles = ref([]);

    const userRole = ref("");
    const canCreate = ref(false);
    onAuthStateChanged(auth, async (user) => {
      userRole.value = await getUserRole(user);
      canCreate.value = ROLES.MEMBER.CREATE.includes(userRole.value?.toLowerCase());
    });

    const getMemberTitle = computed(() => {
      return (memberTitleId) => titles.value[memberTitleId] || "";
    });
    const club = ref({});
    const league = ref({});
    const players = ref([]);
    const members = ref([]);
    const isLoggedUser = ref(false);
    const showAddMemberModal = ref(false);
    const showEditMemberModal = ref(false);
    const showDeleteMemberModal = ref(false);
    const memberIdToDelete = ref(null);
    const route = useRoute();
    const toast = useToast();
    const editingMember = ref(null);
    const initialMember = {
      id: null,
      firstName: "",
      lastName: "",
      middleName: "",
      titleId: "",
      photo: "",
    };

    const localMember = ref({ ...initialMember });

    const showFooter = computed(() => {
      return (currentUser.value && teamOwnerId.value && currentUser.value.id === teamOwnerId.value) || userRole.value?.toLowerCase() == "superadmin";
    });

    const sortedMembers = computed(() => {
      return members.value.sort((a, b) => {
        if (!a.firstName || !b.firstName) {
          return 0; // or some other logic to handle undefined values
        }
        return a.firstName.localeCompare(b.firstName);
      });
    });
    const formatYear = (input) => {
      if (input && typeof input.toDate === "function") {
        return input.toDate().getFullYear();
      } else if (input && typeof input === "string") {
        return input.split("-")[0];
      }
      return "";
    };

    let unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        currentUser.value = { ...user, id: user.uid };
      } else {
        currentUser.value = null;
      }
    });

    watch(currentUser, (newVal, oldVal) => {
      // console.log('currentUser changed:', newVal);
    });

    watch(teamOwnerId, (newVal, oldVal) => {
      // console.log('teamOwnerId changed:', newVal);
    });
    const cancelDelete = () => {
      memberIdToDelete.value = null;
      showDeleteMemberModal.value = false;
    };
    const fetchData = async () => {
      try {
        const teamId = route.params.teamId;

        const qPlayers = query(collection(db, "players"), where("teamId", "==", teamId));
        const playersSnapshot = await getDocs(qPlayers);
        players.value = playersSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        // Fetch members
        const qMembers = query(collection(db, "members"), where("teamId", "==", teamId));
        const membersSnapshot = await getDocs(qMembers);
        members.value = membersSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (members.value) {
          const isLoggedInUser = members.value.find((el) => el.email === currentUser.value.email);
          if (isLoggedInUser) {
            isLoggedUser.value = true;
            emit("updateView", true);
          }
        }
        //Fetch team
        const teamDoc = await getDoc(doc(db, "teams", teamId));
        if (teamDoc.exists()) {
          team.value = teamDoc.data();
          teamOwnerId.value = team.value.ownerId;
        } else {
          console.error("No such team exists!");
        }

        // Fetch the club's details using the club's ID from the team
        // if (team.value && team.value.club) {
        //     const clubDoc = await getDoc(doc(db, "clubs", team.value.club));
        //     if (clubDoc.exists()) {
        //         team.value.clubDetails = clubDoc.data();
        //     } else {
        //         console.error("No such club exists!");
        //     }
        // }

        // Fetch the league's details using the club's leagueId
        if (team.value.clubDetails && team.value.clubDetails.leagueId) {
          const leagueDoc = await getDoc(doc(db, "leagues", team.value.clubDetails.leagueId));
          if (leagueDoc.exists()) {
            league.value = leagueDoc.data();
          } else {
            console.error("No such league exists!");
          }
        }

        // Fetch titles
        const titlesSnapshot = await getDocs(collection(db, "titles"));
        titles.value = titlesSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name;
          return acc;
        }, {});

        isLoading.value = false;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const deleteMember = (memberId) => {
      memberIdToDelete.value = memberId; // set the member ID you want to delete
      showDeleteMemberModal.value = true; // open the confirmation modal
    };

    async function confirmDeleteMember() {
      if (!memberIdToDelete.value) {
        console.error("No member ID provided for deletion.");
        return;
      }
      try {
        const memberRef = doc(db, "members", memberIdToDelete.value);

        await deleteDoc(memberRef);

        await refreshMembers();

        showDeleteMemberModal.value = false;

        toast.success("Member successfully deleted");
      } catch (error) {
        console.error("Error deleting member:", error);
        toast.error("Failed to delete member.");
      }
      memberIdToDelete.value = null;
    }

    // async function confirmDeleteMember() {
    //     if (!memberIdToDelete.value) {
    //         console.error("No member ID provided for deletion.");
    //         return;
    //     }
    //     try {
    //         const memberRef = doc(db, "members", memberIdToDelete.value);
    //         const memberDoc = await getDoc(memberRef);
    //         const memberData = memberDoc.data();

    //         if (memberData && memberData.photo) {
    //     try {
    //         const storageInstance = getStorage();
    //         const storageReference = storageRef(storageInstance, memberData.photo);

    //         await deleteObject(storageReference);
    //     } catch (storageError) {
    //         console.error("Error deleting the photo from storage:", storageError);
    //     }
    // }

    //         await deleteDoc(memberRef);

    //         await refreshMembers();

    //         showDeleteMemberModal.value = false;

    //         toast.success("Member successfully deleted");

    //     } catch (error) {
    //         console.error("Error deleting member:", error);
    //         toast.error("Failed to delete member.");
    //     }
    //     memberIdToDelete.value = null;
    // }
    const router = useRouter();
    const { teamId } = router.currentRoute.value.params;

    async function refreshMembers() {
      try {
        const q = query(collection(db, "members"), where("teamId", "==", teamId));

        const querySnapshot = await getDocs(q);

        members.value = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      } catch (error) {
        console.error("Error fetching staff:", error);
      }
    }

    function handleAddMember() {
      showAddMemberModal.value = true;
    }

    const onMemberAdded = () => {
      showAddMemberModal.value = false;
      fetchData();
      refreshMembers();
    };

    function handleCloseAddMemberModal() {
      showAddMemberModal.value = false;
      refreshMembers();
    }

    function onMemberSaved() {
      showEditMemberModal.value = false;
      fetchData();
      refreshMembers(); // Refresh the player list
    }

    function handleCloseEditMemberModal() {
      showEditMemberModal.value = false;
    }

    function editMember(member) {
      // console.log("Editing member:", member); // Debug
      currentMember.value = member;
      showEditMemberModal.value = true;
    }

    onMounted(async () => {
      await fetchData();

      unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          currentUser.value = { ...user, id: user.uid };
        } else {
          currentUser.value = null;
        }
      });

      await nextTick();
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });
    watch([currentUser, teamOwnerId], () => {
      if (currentUser.value) {
        // console.log('currentUser ID:', currentUser.value.id);
      } else {
        // console.log('currentUser is not set yet.');
      }
      if (teamOwnerId.value) {
        // console.log('teamOwner ID:', teamOwnerId.value);
      } else {
        // console.log('teamOwnerId is not set yet.');
      }
    });

    return {
      activeTab,
      league,
      localMember,
      club,
      team,
      refreshMembers,
      members,
      handleAddMember,
      currentMember,
      currentDeleteType,
      cancelDelete,
      confirmDeleteMember,
      deleteMember,
      editMember,
      editingMember,
      handleCloseEditMemberModal,
      handleCloseAddMemberModal,
      onMemberAdded,
      onMemberSaved,
      showFooter,
      showAddMemberModal,
      // showAddMemberModalFunc,
      showEditMemberModal,
      showDeleteMemberModal,
      sortedMembers,
      isLoading,
      currentUser,
      formatYear,
      getMemberTitle,
      canCreate,
      userId,
      teamOwnerId,
      isLoggedUser,
    };
  },
};
</script>

<!-- STYLES -->

<style scoped lang="scss">
a {
  text-decoration: none;
  cursor: pointer;
}

.shirt-number {
  top: 0em;
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing: -1.5px;
  right: 0em;
  line-height: 1.9;
  background-color: #215178;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 2.4em;
  height: 2.4em;
  text-align: center;
  color: #ffffff;
}

.player-position {
  background-color: #e5f1f8;
  text-align: center;
  color: #2e5571;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 0.3em 0;
}

.item-card {
  &:hover {
    box-shadow: 0 0 12px #999;

    img {
      background-size: 115%;
      background-repeat: no-repeat;
    }
  }

  .svg-inline--fa {
    color: #93bac9;
  }

  .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all ease-in-out 4ms;
    cursor: pointer;
  }
}

.loading-spinner {
  /* Your loading spinner styles */
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
