<template>
  <PreloaderMainComponent v-if="loading" />
  <NavBar v-if="user && user.emailVerified && !loading && route.name != 'Login'" />

  <router-view v-if="!loading" class="mh" />
  <Footer v-if="user && user.emailVerified && !loading && route.name != 'Login'" />
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import { onBeforeMount, ref } from "vue";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { useRouter, useRoute } from "vue-router";
import PreloaderMainComponent from "@/components/PreloaderMainComponent.vue";

export default {
  components: {
    NavBar,
    Footer,
    PreloaderMainComponent,
  },

  setup() {
    const user = ref(null);
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    onBeforeMount(() => {
      auth.onAuthStateChanged(async (currentUser) => {
        if (!currentUser) return;

        // chech if path not home
        if (router.currentRoute.value.path === "/") {
          user.value = currentUser;
        }
      });
      setTimeout(() => (loading.value = false), 1000);
      console.log(route);
    });
    return { user, loading, route };
  },
};
const socket = new WebSocket("ws://localhost:8080");
</script>

<style lang="scss">
$white: #fff;
$bg-body: #e3e7ec;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$accent-800: #072f56;

body {
  min-height: 100vh;
  background-color: $bg-body !important;
}

.mh {
  min-height: 74vh;
}

a,
.btn-link {
  text-decoration: none !important;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-accent-800 {
  background-color: $accent-800;
}

.main-color {
  color: $accent-800;
}

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300;500&family=Poppins:wght@400;500;600&family=Roboto:ital,wght@0,100;0,300;1,100&display=swap");

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
}

$fs-7: 0.75rem;
.fs-7 {
  font-size: $fs-7 !important;
}

body {
  font-family: "Assistant", sans-serif;
  font-weight: 400;
}

.shirt-number {
  font-family: "Assistant", sans-serif;
}

.required .control-label:after {
  content: "*";
  color: red;
}

span.required {
  color: red;
}

form.strong-label label {
  font-weight: 700;
}

.vuejs3-datepicker__calendar-actionarea {
  color: $gray-700;
}
</style>
