<template>
  <div class="container py-5 about">
    <h1 class="mb-4">About TSP</h1>

    <div class="row">
      <div class="col-md-6">
        <p>
          Team Sheet Pro is an innovative online platform dedicated to promoting youth
          soccer. Founded by a passionate parent whose child actively participated in
          youth soccer, our platform addresses a common need faced by teams during
          showcases – the necessity of printed sheets for referees or college scouts.
        </p>
        <p>
          Upon signing up for an account, users gain access to a range of functionalities.
          Team owners can easily create their teams and add players to the squad, manage
          event schedules, and include staff members or volunteers overseeing team
          activities. It's important to note that only team owners can access the private
          information of added players and members. College scouts, on the other hand, can
          view player details, reels, and any other information set to display.
        </p>
        <p>
          Furthermore, team owners have the ability to invite other users to become team
          owners, facilitating collaborative team management.
        </p>
        <p>
          One of the standout features of Team Sheet Pro is the provision of a PDF
          generation tool, enabling users to create printable versions of their team
          information.
        </p>
        <p>
          The Team Sheet Pro team is committed to continually enhancing the platform with
          new features, empowering parents, players, and coaches in efficiently managing
          their team information.
        </p>
      </div>
      <div class="col-md-6">
        <img class="w-100" src="../assets/images/right-logo.png" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <img class="w-100" src="../assets/images/register-screen.png" />
      </div>
      <div class="col-md-6">
        <h2 style="pt-4">How it works</h2>
        <p>
          Register for an account, login, then either create a team or browse to view
          other teams’ information, leagues, clubs and events.
        </p>
        <p>
          To access the specialized features designed for soccer scouts on our platform,
          kindly submit an email request accompanied by formal documentation verifying
          your status as a scout affiliated with a college or university. Upon
          verification, we will assign you a recruiter role, granting you access to the
          app's dedicated tools tailored for scouting purposes.
        </p>
        <h3>Scouts</h3>
        <p>
          will be able to search for teams and view players' details including video
          highlights. Scouts will be able to print the Team Sheet which includes all the
          squad members' contact information: Email, Phone, GPA, school year, etc.
        </p>
        <p>
          As a player, you have the option to enhance your profile by including additional
          information visible to scouts. You can incorporate your YouTube highlights into
          your profile, showcasing your skills and achievements. Furthermore, the app
          allows you to conveniently access a list of all the teams you are affiliated
          with, particularly beneficial if you are part of multiple teams. While the app
          already features a comprehensive list of soccer leagues and numerous clubs,
          users retain the flexibility to add a new club to application as they are adding
          a team.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2 style="py-4">Features</h2>
        <p>
          Watch the step by step registration, login and how to add teams, players and events.
        </p>

        <ol>
          <li>Register</li>
          <li>Validate your account</li>
          <li>Login, update your profile</li>
          <li>Add a team</li>
          <li>Add players</li>
          <li>Add staff members (Coaches and Assistant Coaches)</li>
          <li>Add Events and showcases</li>
          <li>Invite other users to help with team management</li>
          <li>Download the PDF print Sheet, send link to recruiters or to friends</li>
          <li>Send us a request to change your role if you are a recruiter</li>
          <li>Recruiters can see player's detail and contact info</li>

        </ol>
      </div>
      
      <div class="col-md-6 py-3 overflow-auto">
        
        <YouTube class="video-clip" src="https://www.youtube.com/watch?v=2c85ZZ76frs" @ready="onReady" ref="youtube"    />
      </div>
   
    </div>




    <div class="row">
      <div class="col-md-6">
        <h2 style="pt-4">Team Owners</h2>
        <p>
          When you register and create a team, you become a team owner. You will be able
          to add players, staff members and events to your team.
        </p>

        <p>
          As a team owner, you will be able to invite another user to become another team
          owner and manage the team with you.
        </p>
        <p>
          When you add players, but they are not in the system, they will receive an email
          to the address you use for them. They then will receive a notification and have
          an account created for them with a profile that they can update with more
          information.
        </p>
        <p>
          If they are in the system, they will still receive a notification that they have
          been added to a team.
        </p>

        <h2 style="pt-4">Players</h2>
        <p>
          As a player, you have the option to enhance your profile by including additional
          information visible to scouts. You can incorporate your YouTube highlights into
          your profile, showcasing your skills and achievements. Furthermore, the app
          allows you to conveniently access a list of all the teams you are affiliated
          with, particularly beneficial if you are part of multiple teams. While the app
          already features a comprehensive list of soccer leagues and numerous clubs,
          users retain the flexibility to add a new club to application as they are adding
          a team.
        </p>
        <p>For any questions, please send us a message to info@TeamSheetPro.com</p>
      </div>
      <div class="col-md-6">
        <img class="w-100 mt-3" src="../assets/images/players.jpg" />
      </div>
    </div>
    </div>
</template>

<script>
  import {defineComponent} from 'vue'
  import YouTube from 'vue3-youtube'

  export default defineComponent({
    components: {YouTube},
  methods: {
    onReady() {
    this.$refs.youtube.playVideo()
  },
    },
})
</script>
<style scoped lang="scss">

.video-clip {
  width: 100% !important;
  height: 100% !important; 
   iframe {
    display: block;
      width: 100% !important;
      height: 100% !important;
    }
}

</style>
